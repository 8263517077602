import RepositoryBase from "@/repositories/repository-base";
import {IPaginator} from "@/contracts/i-paginator";

export default class UserApprovalRequestRepository extends RepositoryBase<any> {
  public constructor(userId) {
    super();
    this.endpoint = `users/${userId}/approval-requests`;
  }

  public async getTodoCounts(): Promise<{
    requested: number;
    requesting: number;
  }> {
    const res = await this.get(this.endpoint + '/todo-counts');
    return res.data;
  }

  public async getRequestingList(opt: { page: number; status: RequestStatus } ): Promise<IPaginator<RequestingApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/requesting-list', opt);
    return this.toPagination(res);
  }

  public async listRequestedNeedYourApprovalNow(opt: { page: number } ): Promise<IPaginator<RequestedApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/requested-now', opt);
    return this.toPagination(res);
  }
  public async listRequestedOngoingWorkflow(opt: { page: number } ): Promise<IPaginator<RequestedApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/requested-ongoing', opt);
    return this.toPagination(res);
  }
  public async listRequestedAll(opt: { page: number } ): Promise<IPaginator<RequestedApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/requested-all', opt);
    return this.toPagination(res);
  }
}

export class CompanyApprovalRequestRepository extends RepositoryBase<any> {
  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/approval-requests`;
  }
  public async listOngoing(opt: { page: number } ): Promise<IPaginator<RequestingApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/ongoing', opt);
    return this.toPagination(res);
  }
  public async listAll(opt: { page: number } ): Promise<IPaginator<RequestedApprovalFlowRow>> {
    const res = await this.get(this.endpoint + '/all', opt);
    return this.toPagination(res);
  }
}

export enum RequestStatus {
  Waiting = 'waiting',
  Done = 'done',
}

export type RequestingApprovalFlowRow = {
  id: number;
  parentId: number | null;
  name: string;
  type: 'spec' | 'specAcceptance' | 'selfSubmission' | 'submission';
  currentStep: number;
  currentStepName: string;
  totalStep: number;
  pastDays: number;
  requestedAt: Date;
  requesterName: string;
  approved: boolean;
};
export type RequestedApprovalFlowRow = RequestingApprovalFlowRow;

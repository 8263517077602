import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import {alert} from '@/utils/notification';
import {AuthUser} from "@/libs/auth";
import {Extras} from "@sentry/types/dist/extra";

export class ApiError implements Error {
  public name = 'ApiError';
  public constructor(public message: string, public httpStatus: number = 0) { }
  public toString() {
    return this.message;
  }
}
export class HandledApiError extends ApiError {
  public name = 'HandledApiError';
}
export class UnHandledApiError extends ApiError {
  public name = 'UnHandledApiError';
}
export const HttpStatusCode = {
  Raw: 470,
}

window.Sentry = Sentry;

export function registerSentry() {
  // register sentry
  if (process.env.VUE_APP_REPORT_ENV) {
    Sentry.init({
      // dsn: 'https://e2fe69f86bfb47949cb93f0401661e37@o318345.ingest.sentry.io/1805511', 消す
      dsn: 'https://94f7fdac60814d0e817308e48d77a59c@o269961.ingest.sentry.io/6154218',
      ignoreErrors: [
        /Non-Error promise rejection captured with .*/,
        /Non-Error exception captured with .*/,
        '404 (Not Found)',
        'Failed to fetch',
        'ResizeObserver loop limit exceeded',
        /ResizeObserver loop completed with undelivered notifications/,
        '通信中にエラーが発生しました(Network Error)',
        'ネットワーク接続が切れました。',
        'ログアウトされています',
        'キャンセルしました',
        'cancelado',
        'Fuji is not defined',
        /Redirected from .* via a navigation guard/,
        /Redirected when going from ".*?" to ".*?" via a navigation guard./,
        /Navigation aborted from ".*?" to ".*?" via a navigation guard./,
        /NetworkError when attempting to fetch resource./
      ],
      integrations: [
        new Integrations.Dedupe(),
        new Integrations.Vue({
          Vue,
          attachProps: true,
          logErrors: true,
          tracingOptions: {trackComponents: true }
        }),
        // new Integrations.CaptureConsole({ levels: ['error'] })
      ],
    });

    Sentry.configureScope(scope => {
      scope.setTag('environment', process.env.VUE_APP_REPORT_ENV!);
    });
  }
}

export function setSentryUser(user: AuthUser) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: user.id.toString(),
      email: user.email,
      username: user.name,
      ...user as any
    });
  });
}

export function handleError(error:Error, errorMessage:string, original:any, extras: Extras = {}) {
  console.error(error);
  alert(errorMessage);
  if (error instanceof HandledApiError) return;
  Sentry.captureException(original, { extra: extras });
}

export function createExtrasFromVm(vm: Vue) {
  const extras = {
    name: vm.$options.name,
    componentTag: vm.$options['_componentTag'],
    className: vm.$el ? vm.$el.className : undefined,
    props: vm.$options.propsData,
  }
  const parent = vm.$parent;
  if (parent) {
    Object.assign(extras, {
      parentName: parent.$options.name,
      parentComponentTag: parent.$options['_componentTag'],
      parentClassName: parent.$el ? parent.$el.className : undefined,
      parentProps: parent.$options.propsData,
    });
  }
  return extras;
}


export function registerErrorHandling() {
  registerSentry();

  // エラー時にアラートを表示する
  // Vueの描画関数やWatcherなどで発生したエラーを補足
  const original = Vue.config.errorHandler; // Sentryのこのフックつかってるから、消さないように
  Vue.config.errorHandler = (error:any, vm, info) => {
    // 遅延コンポーネント読み込み、またはVue Routerで遅延読み込みするときに、該当ファイルが消えていると発生するエラー
    if (error.name === 'ChunkLoadError' || (error.message && error.message.match(/Couldn't resolve component.*/)) ) {
      alert('アプリケーションが更新されているため読み込みに失敗しました。ページを再読み込みしてください。');
      return;
    }

    if (original) {
      original(error, vm, info);
    }

    const errorMessage = error.message || error;
    const extras = createExtrasFromVm(vm);
    handleError(error, errorMessage, error, extras);
  }
  window.addEventListener("error", (event:ErrorEvent) => {
    if (event.message.match(/^ResizeObserver loop/)) return;
    const errorMessage = event.message;
    handleError(event.error, errorMessage, event);
  });

  window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
    if (!event.reason) return;
    const errorMessage = event.reason.message || event.reason;
    handleError(event.reason, errorMessage, event);
  });

  if (process.env.NODE_ENV === 'testing') {
    // cypressでpromiseのerrorをキャッチするため
    window.addEventListener('unhandledrejection', (evt:any) => console.error(evt.reason), false);
  }
}


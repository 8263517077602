export default {
  computed: {
    active() {
      // 'ingredient', 'preproduct', 'product', 'faq'
      if (!this.$route.name) return false;
      return this.$route.name.split('.')[0];
    }
  },
  methods: {
    getRoute(name, params = {}) {
      if (!this.$auth.isAuthenticated) return {name: name};
      const companyId = this.$route.params.companyId || this.$auth.user.company.id;
      return {name: name, params: { companyId, ...params } };
    },
  }
}

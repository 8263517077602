import {createMaxStringRule, createMinRule, requiredRule} from "@/utils/validation-rules";
import ObjectUtils from "@/utils/object-utils";
import {i18n} from "@/bootstraps/locale";
import {CustomValueInputType} from "@/entities/interfaces/i-custom-value";

export interface ICustomValueSetting {
  inputType: CustomValueInputType;
  title: string;
  selections: string[];
  readonly isEmpty: boolean;
}

export class CustomValueSettingEntityBase implements ICustomValueSetting {
  public id!: number;
  public inputType!: CustomValueInputType;
  public title!: string;
  public selections!: string[];
  // public required!: boolean;

  constructor(init:Partial<CustomValueSettingEntityBase> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.selections = init.selections || [];
  }

  public get isEmpty(): boolean {
    return !this.title && !this.inputType;
  }
}

export function serializeCustomValueSetting(data: ICustomValueSetting[]): ICustomValueSetting[] {
  return data.filter(d => !d.isEmpty);
}

export const CustomValueInputTypeDictForSetting = [
  { id: CustomValueInputType.Text, label: i18n.t('テキスト') },
  { id: CustomValueInputType.Number, label: i18n.t('数字') },
  { id: CustomValueInputType.Select, label: i18n.t('選択肢') },
  { id: CustomValueInputType.Date, label: i18n.t('日付') },
  { id: CustomValueInputType.Money, label: i18n.t('通貨') },
];

export const ValidatorRules = {
  title: [ requiredRule, createMaxStringRule()],
  inputType: [ requiredRule ],
  selections: [ requiredRule, createMinRule(1, 'array') ],
};

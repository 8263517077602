import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import InvitingMemberEntity from "@/entities/inviting-member-entity";
import appType from "@/app-types";
import {HandledApiError} from "@/bootstraps/error-handler";
import {AuthUser} from "@/libs/auth";

export interface EmailVerificationParams {
  email: string;
  expiredAt: string;
  sign: string;
  meta?: string;
}

export default class AuthRepository extends RepositoryBase<any> {
  protected endpoint:string = 'auth/';
  protected ctor:new(data) => AuthUser = AuthUser;

  // region Registration
  public async sendVerifyRegistrationEmail(email: string) {
    return this.post(this.endpoint + "registration/send-verification-email", { email });
  }

  public async signup(params:EmailVerificationParams, request:SignupRequest): Promise<AuthUser> {
    const res = await this.post(this.endpoint + 'registration/signup', {
      user: request.user,
      password: request.password,
      verification: params,
      appType: appType
    });
    return new this.ctor(res.data);
  }

  public async fetchInvitingMember(token): Promise<InvitingMemberEntity> {
    const res = await this.get(this.endpoint + 'registration/fetch-inviting-member', { verification: token });
    return new InvitingMemberEntity(res.data);
  }
  public async signupFromInvitation(params:EmailVerificationParams, password:string, user:{name:string;}): Promise<AuthUser> {
    const res = await this.post(this.endpoint + 'registration/signup-from-invitation', {
      user: user,
      password: password,
      verification: params,
      appType: appType
    });
    return new this.ctor(res.data);
  }
  // endregion Registration

  // region Login/Logout
  public async login(email:string, password:string): Promise<AuthUser | null> {
    try {
      const res = await this.post(this.endpoint + 'login', { email, password });
      if (typeof res.data === 'string' && res.data === 'mfa_required') {
        return null;
      }
      return new this.ctor(res.data);
    } catch (error) {
      if (error instanceof HandledApiError && error.httpStatus === 422) {
        return Promise.reject(new HandledApiError('ログインに失敗しました。メールアドレスをパスワードをご確認ください。'));
      }
      throw error;
    }
  }
  public async loginWithMFACode(email:string, password:string, mfaCode?: string): Promise<AuthUser> {
    const res = await this.post(this.endpoint + 'login/mfa', {email, password, mfaCode});
    return new this.ctor(res.data);
  }
  public async regenerateMfaCode(email:string, password:string): Promise<boolean> {
    const res = await this.post(this.endpoint + 'login/regenerate-mfa', { email, password });
    return res.data;
  }

  public async logout() {
    return await this.get(this.endpoint + 'logout');
  }

  public async retrieve():Promise<AuthUser|null> {
    try {
      const res = await this.get(this.endpoint + 'retrieve');
      return new this.ctor(res.data);
    } catch (error) {
      if (error instanceof HandledApiError && error.httpStatus === 401) {
        return null; // not login
      }
      throw error;
    }
  }
  // endregion Login/Logout

  // region ForgetPassword
  public async sendPasswordResetEmail(email: string) {
    return this.post(this.endpoint + "forget-password/send-email", {email, appType: appType});
  }
  public async resetPassword(params: EmailVerificationParams, password: string) {
    return this.post(this.endpoint + "forget-password/reset-password", {
      verification: params,
      password,
      appType: appType
    });
  }
  // endregion ForgetPassword

  // region update
  public async sendVerifyChangingEmail(email: string) {
    return this.post(this.endpoint + "user/send-email", {email, appType: appType});
  }
  public async changePassword(currentPassword, newPassword) {
    return this.put(this.endpoint + "user/password", {currentPassword, password: newPassword, appType: appType});
  }
  // endregion update

}

export type SignupRequest = {
  password: string;
  user: {
    name: string;
    company: {
      name: string;
      telno: string;
    }
  }
}

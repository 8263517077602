import CompanyEntity from "@/entities/company-entity";
import {ApprovalRequestEntity, IHasApprovalRequest} from "@/entities/approval-request-entity";

export class ApprovalService {
  constructor(private company: CompanyEntity, private forSubmission: boolean) {
  }

  public approvalSettingEnabled(): boolean {
    if(!this.company.setting.isApprovalFlowEnabled) return false;
    return this.forSubmission
      ? this.company.setting.approvalFlowForOwnedSpecEnabled
      : this.company.setting.approvalFlowForAcceptanceEnabled;
  }

  public needsMoreApproval(approvalRequest: ApprovalRequestEntity | null): boolean {
    if(!this.approvalSettingEnabled()) return false;
    if (!approvalRequest) return true;
    return !approvalRequest.isCompleted;
  }

  public resourceEditableStatus(resource: IHasApprovalRequest): ResourceEditableStatus {
    if(!this.approvalSettingEnabled()) return ResourceEditableStatus.OK;

    const settingEditable = this.forSubmission
      ? this.company.setting.approvalFlowForOwnedSpecIsEditableOnProgress
      : this.company.setting.approvalFlowForAcceptanceIsEditableOnProgress;

    const onProgress = resource.approvalRequest && !resource.approvalRequest.isCompleted;
    if (onProgress) {
      return settingEditable ? ResourceEditableStatus.ALERT : ResourceEditableStatus.NG;
    } else {
      return ResourceEditableStatus.OK;
    }
  }
}

export enum ResourceEditableStatus {
  OK = 'ok',
  NG = 'ng',
  ALERT = 'alert',
}

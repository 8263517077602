import ObjectUtils from "@/utils/object-utils";
import parseISO from 'date-fns/parseISO';

export default class NotificationEntity {
  public id!:number;
  public companyId!:number;
  public content!:string;
  public targetUrl!:string;
  public createdAt!:Date;

  constructor(init:Partial<NotificationEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }
  }
}

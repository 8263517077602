import {
  createMaxStringRule,
  createMinRule,
  requiredOnBlurRule,
  requiredRule,
  typeNumberOrEmptyRule
} from "@/utils/validation-rules";
import {i18n} from "@/bootstraps/locale";
import ObjectUtils from "@/utils/object-utils";

export enum CustomValueInputType {
  Text = 1,
  Boolean = 2,
  Number = 3,
  Select = 4,
  Date = 5,
  Money = 6,
}

export interface ICustomValue {
  inputType: CustomValueInputType;
  title: string;
  value: string;
  value2: string | null;
}

export class CustomValueEntityBase implements ICustomValue {
  public inputType: CustomValueInputType = CustomValueInputType.Text;
  public title: string = '';
  public value: string = '';
  public value2: string | null = null;

  constructor(init: Partial<CustomValueEntityBase> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export function isCustomValueEmpty(cv: ICustomValue) {
  return !cv.title && !cv.value;
}

export function formatCustomValueValue(cv: ICustomValue) {
  if (!cv.value) return '';

  switch (cv.inputType) {
    case CustomValueInputType.Text:
    case CustomValueInputType.Select:
      return cv.value;
    case CustomValueInputType.Date:
      return cv.value;
    case CustomValueInputType.Number:
      return Number(cv.value).toLocaleString(undefined, { maximumFractionDigits: 9 });
    case CustomValueInputType.Money:
      return Number(cv.value).toLocaleString(undefined, { maximumFractionDigits: 9 }) + `（${cv.value2}）`;
    default:
      return cv.value;
  }
}

export const CustomValueInputTypeDict = [
  { id: CustomValueInputType.Text, label: i18n.t('テキスト') },
  { id: CustomValueInputType.Number, label: i18n.t('数字') },
  { id: CustomValueInputType.Date, label: i18n.t('日付') },
  { id: CustomValueInputType.Money, label: i18n.t('通貨') },
];

export const ValidatorRules = {
  title: [requiredOnBlurRule, createMaxStringRule()],
  value: {
    text: [ createMaxStringRule()],
    date: [ ],
    number: [ typeNumberOrEmptyRule ],
    select: [ ],
  },
  inputType: [ requiredOnBlurRule ],
};


export default interface CompanyDepartmentEntity {
  id:number;
  companyId:number;
  name: string;
  memberCount: number;
}

export function getDepartmentNames(withVirtualManagementTag = true, departments:CompanyDepartmentEntity[]): string[] {
  return (withVirtualManagementTag ? ['管理'] : []).concat(departments.map(d => d.name));
}














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:boolean;
  @Prop({required: true}) private items!:any[];

  @Prop({default: true}) private allowCreate!:boolean;
  @Prop({default: true}) private clearable!:boolean;

  @Prop({default: undefined}) private placeholder?:string;
  @Prop({default: undefined}) private dropdownWidth!:string|undefined;

  private get d_value() { return this.value; }
  private set d_value(val) { this.$emit('input', val); }
}

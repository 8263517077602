import RepositoryBase, {handleAxiosError} from "@/repositories/repository-base";
import {tokenErrors} from '@/libs/payjp';
import {HandledApiError} from "@/bootstraps/error-handler";

import appType, {AppTypes} from "@/app-types";
import fromUnixTime from "date-fns/fromUnixTime";
import {i18n} from "@/bootstraps/locale";

export default class PaymentRepository extends RepositoryBase<Object> {
  protected ctor:new(data) => Object = Object;

  private readonly appType!:AppTypes;

  public constructor(companyId, type:AppTypes = appType) {
    super();
    this.appType = type;
    this.endpoint = `companies/${companyId}/payments`
  }

  private handleError(error) {
    if (error.code in tokenErrors) {
      // payjp error
      throw new HandledApiError(tokenErrors[error.code]);
    } else {
      return handleAxiosError(error);
    }
  }

  public async createSubscription(tokenId:string|null, couponCode:string|null = null) {
    return await super.post(this.endpoint + "/create-subscription", {token: tokenId, coupon_code: couponCode, appType: this.appType}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }
  public async appendSubscription() {
    return await super.post(this.endpoint + "/append-subscription", {appType: this.appType}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }
  // payjpにはデータ作成されない。db.subscription_specだけ作成される(use_payjp=false)
  public async createFreeSubscription() {
    return await super.post(this.endpoint + "/create-free-subscription", {appType: this.appType}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }

  public async changeCard(tokenId:string) {
    return await super.post(this.endpoint + "/change-card", {token: tokenId}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }

  public async getPaymentInfo(): Promise<PaymentInfo> {
    const res = await super.get(this.endpoint);
    return new PaymentInfo(res.data);
  }

  public async suspendSubscription() {
    return await super.post(this.endpoint + "/suspend-subscription", {appType: this.appType}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }

  public async resumeSubscription() {
    return await super.post(this.endpoint + "/resume-subscription", {appType: this.appType}, { skipErrorHandler: true } as any)
      .catch(this.handleError);
  }
}

export interface Plan {
  name:string;
  displayName:string;
  amount:number;
  isFreePlan:boolean;
}
export interface Card {
  brand:string;
  last4:string;
}
export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive',
  NotAvailable = 'not_available'
}
export class Subscription {
  public plan!:Plan;
  public currentPeriodEnd!:number;
  public type!:AppTypes;
  public status!:'active'|'trial'|'canceled'|'paused';
  public nextCyclePlan:Plan|null = null;
  public isAvailable!:boolean;

  public constructor(data:Subscription, type:AppTypes) {
    this.plan = data.plan;
    this.currentPeriodEnd = data.currentPeriodEnd;
    this.status = data.status;
    this.nextCyclePlan = data.nextCyclePlan;
    this.isAvailable = data.isAvailable;

    this.type = type;
  }

  public getStatus(): SubscriptionStatus {
    if(!this.isAvailable){
      return SubscriptionStatus.NotAvailable;
    } else if(['canceled', 'paused'].includes(this.status)) {
      return SubscriptionStatus.Inactive;
    } else {
      return SubscriptionStatus.Active;
    }
  }

  public get nextPaymentDate():Date {
    return fromUnixTime(this.currentPeriodEnd);
  }
  public get nextPlanName() {
    return this.nextCyclePlan ? this.nextCyclePlan.displayName : '';
  }
}
export class Charge {
  public id!:string;
  public created!:number;
  public service!:string;
  public captured!:boolean;
  public amount!:number;
  public card!:Card;

  public constructor(data:Charge) {
    this.id = data.id;
    this.created = data.created;
    this.service = data.service;
    this.captured = data.captured;
    this.amount = data.amount;
    this.card = data.card;
  }

  public get createdAt(): Date {
    return fromUnixTime(this.created);
  }

  public get serviceName():string {
    switch (this.service) {
      case 'spec': return i18n.t('スマート食品規格書');
      case 'label': return i18n.t('スマート食品表示');
      default: return this.service;
    }
  }

}
export class PaymentInfo {
  public subscriptionLabel:Subscription|null = null;
  public subscriptionSpec:Subscription|null = null;
  public card!:Card|null;
  public charges!:Charge[];
  public subscriptionSpecPaymentChannel!:PaymentChannel;

  public constructor(data:PaymentInfo) {
    if (data.subscriptionLabel) {
      this.subscriptionLabel = new Subscription(data.subscriptionLabel, AppTypes.Label);
    }
    if (data.subscriptionSpec) {
      this.subscriptionSpec = new Subscription(data.subscriptionSpec, AppTypes.Spec);
    }
    this.card = data.card || null;
    this.charges = data.charges.map(c => new Charge(c));
    this.subscriptionSpecPaymentChannel = data.subscriptionSpecPaymentChannel;
  }

  public isSpecFreePlan():boolean {
    return this.subscriptionSpecPaymentChannel === PaymentChannel.Free;
  }
}

export enum PaymentChannel {
  PayJP = 1,
  Invoice = 2,
  Free = 3,
}

var render = function (_h,_vm) {var _c=_vm._c;return _c('li',{staticClass:"c-filterable-select__dropdown__item",class:[
      {
        'hover': _vm.props.parent.isHover(_vm.props.item),
        'heading': _vm.props.item.isHeading,
        'disabled': _vm.props.item._option_disabled,
      },
      _vm.data.class, _vm.data.staticClass
    ],on:{"mouseenter":function($event){return _vm.props.parent.hoverItem(_vm.props.item)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.props.parent.itemSelected(_vm.props.item)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }



























import { Component, Prop, Vue } from 'vue-property-decorator';
import NotificationEntity from "@/entities/notification-entity";
import {NotificationRepository} from "@/repositories/company/notification-repository";
import UserRepository from "@/repositories/user-repository";
import IconBell from "@/components/Icons/IconBell.vue";
let intervalHandler:any = null;

@Component({
  components: {IconBell}
})
export default class extends Vue {
  private CHECK_FREQUENCY_SECONDS = 300; // 5分
  private notifications:NotificationEntity[]|null = null;

  private dropdownShown = false;
  private badgeVisible = false;

  private created() {
    this.load();

    intervalHandler = setInterval(() => {
      this.load();
    }, 1000 * this.CHECK_FREQUENCY_SECONDS);
  }
  private beforeDestroy() {
    clearInterval(intervalHandler);
  }

  private load() {
    (new NotificationRepository(this.$auth.user.company.id)).findNotificationsForCurrentApp().then(notifications => {
      this.notifications = notifications;
      this.badgeVisible = this.hasUnread;
    });
  }

  private get hasUnread() {
    if (!this.notifications || !this.notifications.length) return false;
    return (this.notifications[0].id > this.$auth.user.maxReadNotificationId);
  }

  private visibleChanged(visible) {
    this.dropdownShown = visible;

    if (visible) {
      // onOpen
      this.badgeVisible = false;
    } else {
      // onClose
      if (this.hasUnread) {
        this.$auth.user.maxReadNotificationId = this.notifications![0].id;
        (new UserRepository).markAsRead(this.$auth.user.id, this.notifications![0].id);
      }
    }
  }
}

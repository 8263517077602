export default {
  data() {
    return {
      canPressEnter: true,
    }
  },
  methods: {
    preventEnter() {
      this.canPressEnter = false;
    },
    enableEnter() {
      this.canPressEnter = true;
    },
    handleKeydownEnter(ev) {
      if (!this.canPressEnter) {
        ev.stopPropagation();
        ev.preventDefault();
      } else {
        this.onPressEnter(ev);
      }
    }
  },
}

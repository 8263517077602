<template>
  <responsive-header-component-wrapper>
    <template v-slot:logo>
      <router-link :to="getRoute($auth.isAuthenticated ? 'spec.index': 'lp')" >
        <img class="u-col24" src="@/assets/spec/header-logo.svg" />
      </router-link>
    </template>
  </responsive-header-component-wrapper>
</template>

<script>
  import ResponsiveHeaderComponentWrapper from "./ResponsiveHeaderComponentWrapper";
  import GetRouteMixin from "./GetRouteMixin";
  export default {
    mixins: [GetRouteMixin],
    components: {
      ResponsiveHeaderComponentWrapper,
    },
  }
</script>

import {createMaxStringRule, requiredRule, typeTelno} from "@/utils/validation-rules";
import ObjectUtils from "@/utils/object-utils";
import {i18n} from "@/bootstraps/locale";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

export class PartnerBase {
  public id!:number;
  public name:string = '';
  public nameKana:string = '';
  public country:string = '';
  public prefecture:string = '';
  public zipcode:string = '';
  public address:string = '';
  public building:string = '';
  public telno:string = '';
  public faxno:string = '';
  public hasPl:boolean|null = null;
  public homepageUrl:string = '';
  public email:string = '';

  public operatorName:string = '';
  public note:string = '';

  constructor(init:Partial<PartnerBase> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public getFullAddress() {
    return (this.country ? this.country + ' / ' : '') + (this.prefecture || '') + (this.address || '') + (this.building || '');
  }
  public getDomesticAddress() {
    return (this.prefecture || '') + (this.address || '') + (this.building || '');
  }
  public get longLabel() {
    return `${this.name}（${this.getFullAddress()}、${i18n.t('電話番号')}：${this.telno || i18n.t('未設定')}、${i18n.t('FAX番号')}：${this.faxno || i18n.t('未設定')}）`;
  }

  public get hasPLLabel(): string {
    if(this.hasPl === null) return i18n.t('未設定');
    return i18n.t(this.hasPl ? '有り' : '無し');
  }

  // TODO: 余計なプロパティもコピーされてしまうため、ちゃんとコピーする
  public import(data: PartnerBase) {
    Object.assign(this, data);
    return this;
  }
}

export const ValidatorRules = {
  name: [requiredRule, createMaxStringRule()],
  nameKana: [createMaxStringRule()],
  country: [createMaxStringRule()],
  prefecture: [createMaxStringRule()],
  address: [createMaxStringRule()],
  building: [createMaxStringRule()],
  telno: [],
  faxno: [],
  operatorName: [createMaxStringRule()],
  email: [createMaxStringRule(4096)],
  homepageUrl: [createMaxStringRule(4096)],
};


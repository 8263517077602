




import { Component, Prop, Vue } from 'vue-property-decorator';
import { judgeLang } from '@/bootstraps/locale';

@Component
export default class extends Vue {

  private src:string = '';

  created() {
    const lang = judgeLang();
    this.src = require(`@/assets/label/header-logo-${lang}.svg`);
  }

}

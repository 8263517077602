import emitter from "element-ui/src/mixins/emitter";

// @see client/node_modules/element-ui/packages/input/src/input.vue
export const InputMixin = {
  mixins: [emitter],
  props: {
    validateEvent: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    value(val) {
      if (this.validateEvent) {
        this.dispatch('FormItemText', 'el.form.change', [val]);
        this.dispatch('FormItem', 'el.form.change', [val]);
        this.dispatch('ElFormItem', 'el.form.change', [val]);
      }
    },
  },
  methods: {
    handleFocus(event) {
      // this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      // this.focused = false;
      this.$emit('blur', event);
      if (this.validateEvent) {
        this.dispatch('FormItem', 'el.form.blur', [this.value]);
        this.dispatch('FormItemText', 'el.form.blur', [this.value]);
        this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
      }
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
  },
}


class StringUtil{
  public toSnakeCase(str:string):string {
    return str.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  public toCamelCase(str: string): string {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/[-_](.)/g, function(match, group1) {
      return group1.toUpperCase();
    });
  }

  public toSnakeCaseObjectKey(data:{}|any[]|any, recursive:boolean = true) {
    return this.changeCase(this.toSnakeCase, data, recursive);
  }

  public toCamelCaseObjectKey(data:{}|any[]|any, recursive:boolean = true) {
    return this.changeCase(this.toCamelCase, data, recursive);
  }

  private changeCase(convertCase:(string) => string, data:any, recursive:boolean) {

    const isObject = o => o === Object(o);

    if (Array.isArray(data)) {
      return data.map(d => {
        return this.changeCase(convertCase, d, recursive);
      });
    } else if(isObject(data)) {
      const result = {};

      Object.keys(data).forEach(key => {
        if (recursive) {
          result[convertCase(key)] = isObject(data[key]) ? this.changeCase(convertCase, data[key], recursive) : data[key];
        } else {
          result[convertCase(key)] = data[key];
        }
      });

      return result
    } else {
      return data;
    }
  }

  public kanaToHira(str) {
    return str.replace(/[\u30a1-\u30f6]/g, function(match) {
      let chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  };

  public hiraToKana(str) {
    if (!str) return '';
    return str.replace(/[\u3041-\u3096]/g, function(match) {
      let chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  };

  public toHankakuKanaLower(str) {
    return this.toHankaku(this.hiraToKana(str).toLowerCase());
  }
  public matchHankakuKanaLower(haystack, needle) {
    return this.toHankakuKanaLower(haystack).indexOf(needle) != -1;
  }

  public matchIgnoreCase(haystack, needle) {
    return this.matchHankakuKanaLower(haystack, this.toHankakuKanaLower(needle));
  }


  public toHankaku(str) {
    // 英数記号
    str = str.replace(/[！-～]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
      .replace(/”/g, "\"")
      .replace(/’/g, "'")
      .replace(/‘/g, "`")
      .replace(/￥/g, "\\")
      .replace(/　/g, " ")
      .replace(/〜/g, "~")
      .replace(/。/g, '.')
      .replace(/．/g, '.')
      .replace(/、/g, ',');

    str = this.zenkakuKanaToHankaku(str);

    return str;
  };

  public zenkakuKanaToHankaku(str){

    const kanaMap = {
      "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ", "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
      "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
      "バ": "ﾊﾞ", "パ": "ﾊﾟ", "ビ": "ﾋﾞ", "ピ": "ﾋﾟ", "ブ": "ﾌﾞ", "プ": "ﾌﾟ", "ベ": "ﾍﾞ", "ペ": "ﾍﾟ", "ボ": "ﾎﾞ", "ポ": "ﾎﾟ",
      "ヴ": "ｳﾞ", "ー": "ｰ",
      "ァ": "ｧ", "ア": "ｱ", "ィ": "ｨ", "イ": "ｲ", "ゥ": "ｩ", "ウ": "ｳ", "ェ": "ｪ", "エ": "ｴ", "ォ": "ｫ", "オ": "ｵ",
      "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ", "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
      "タ": "ﾀ", "チ": "ﾁ", "ッ": "ｯ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ", "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
      "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ", "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
      "ャ": "ｬ", "ヤ": "ﾔ", "ュ": "ｭ", "ユ": "ﾕ", "ョ": "ｮ", "ヨ": "ﾖ", "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
      "ヮ": "ﾜ", "ワ": "ﾜ", "ヰ": "ｲ", "ヱ": "ｴ", "ヲ": "ｦ", "ン": "ﾝ", "ヵ": "ｶ", "ｹ": "ヶ",
    };

    const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
      .replace(reg, match => kanaMap[match])
      .replace(/ﾞ/g, '゛')
      .replace(/ﾟ/g, '゜');
  }
}

export default new StringUtil();

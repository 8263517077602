








































































import GetRouteMixin from "./GetRouteMixin";
import appType, { AppTypes } from "@/app-types";
export default {
  mixins: [GetRouteMixin],
  computed: {
    appType() {
      return appType;
    },
    isLabelApp: () => appType === AppTypes.Label,
  },
  data() {
    return {
      drawerVisible: false,
    };
  },
};

const commonRoutes = [
  {
    path: 'auth',
    component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
    meta: {skipE2E: true},
    children: [
      {
        name: 'auth.login', props: true, path: 'login', meta: {title: "ログイン", guard: 'guest'},
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Login.vue'),
      },
      {
        name: 'auth.logout', path: 'logout',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Logout.vue'),
      },
      {
        path: 'signup',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Router.vue'),
        children: [
          {
            name: 'auth.signup', path: '/', props: true, meta: {title: '会員登録', guard: 'guest'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Signup/Index.vue'),
          },
          {
            name: 'auth.signup.verifying', path: 'verifying', meta: {title: 'メール送信 | 会員登録'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Signup/Verifying.vue'),
          },
          {
            name: 'auth.signup.verified', path: 'verified', meta: {title: '本人確認完了 | 会員登録'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Signup/Verified.vue'),
          },
          {
            name: 'auth.signup.register-card', path: 'register-card', meta: {title: "カード情報入力 | 会員登録"},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Signup/RegisterCard.vue'),
          },
          {
            name: 'auth.signup.complete', path: 'complete', meta: {title: '完了 | 会員登録'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Signup/Complete.vue'),
          },
        ]
      },
      {
        path: 'invitation',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Router.vue'),
        children: [
          {
            name: 'auth.invitation.verified', path: 'verified', meta: {title: '組織へ参加 | 会員登録'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Invitation/Verified.vue'),
          },
          {
            name: 'auth.invitation.complete', path: 'complete', meta: {title: '完了 | 組織に参加'},
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/Invitation/Complete.vue'),
          },
        ]
      },
      {
        name: 'auth.forget-password.index', path: 'forget-password', meta: {title: "確認メール送信 | パスワード再設定"},
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/ForgetPassword/Index.vue'),
      },
      {
        name: 'auth.forget-password.email-sent', path: 'forget-password/email-sent', meta: {title: "完了 | パスワード再設定"},
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/ForgetPassword/EmailSent.vue'),
      },
      {
        name: 'auth.forget-password.reset-password',
        path: 'forget-password/reset-password',
        props: true,
        meta: {title: "パスワード再設定"},
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/ForgetPassword/ResetPassword.vue'),
      },
      {
        name: 'auth.forget-password.complete', path: 'forget-password/complete', meta: {title: "完了 | パスワード再設定"},
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/common/auth/ForgetPassword/Complete.vue'),
      },
    ],
  },
  {
    path: 'mypage/',
    component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/user/MypageApp.vue'),
    meta: { guard: 'auth', skipE2E: true},
    children: [
      { name: 'mypage.user.profile', path: 'profile', alias: '', meta: {title: '個人プロフィール | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/user/Profile.vue'), },
      { name: 'mypage.user.email', path: 'email', meta: {title: 'メールアドレス | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/user/Email.vue'), },
      { name: 'mypage.user.password', path: 'password', meta: {title: 'パスワード | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/user/Password.vue'), },
    ],
  },
  {
    path: 'mypage/companies/:companyId/',
    component: () => import(/* webpackChunkName: "views/mypageCompany" */ '@/views/common/mypage/company/MypageCompanyApp.vue'),
    meta: { guard: 'auth', skipE2E: true},
    children: [
      { name: 'mypage.company.profile', path: 'profile', meta: {title: '組織プロフィール | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Profile.vue'), },
      {
        path: 'clients',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
        children: [
          { name: 'mypage.company.client', path: '/', meta: {title: '回収・提出先 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Client/ClientIndex.vue'), },
          { name: 'mypage.company.client.edit', path: ':clientId/edit', meta: {title: '回収・提出先編集 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Client/ClientEdit.vue'), },
        ]
      },
      {
        path: 'self-submitters',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
        children: [
          { name: 'mypage.company.self-submitter.edit', path: ':selfSubmitterId/edit', meta: {title: '提出元編集 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Client/SelfSubmitterEdit.vue'), },
        ]
      },
      {
        path: 'partners',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
        children: [
          { name: 'mypage.company.partner', path: '/', meta: {title: '関連事業者 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Partner/PartnerIndex.vue'), },
          { name: 'mypage.company.partner.edit', path: ':partnerId/edit', meta: {title: '関連事業者編集 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Partner/PartnerEdit.vue'), },
        ]
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
        children: [
          {
            path: 'label',
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
            children: [
              { name: 'mypage.company.setting-label', path: '/', meta: {title: '食品表示設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/SettingLabel.vue'), },
              { name: 'mypage.company.setting-label.sato', path: 'sato', meta: {title: 'Sato設定 | 食品表示設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/Sato/SatoSetting.vue'), },
            ]
          },
          {
            path: 'spec',
            component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
            children: [
              { name: 'mypage.company.setting-spec', path: '/', meta: {title: '規格書設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/SettingSpec.vue'), },
              { name: 'mypage.company.setting-spec.self-submission.custom-value-setting', path: 'self-submission/custom-value-setting', meta: {title: '追加項目 | 手動回収規格書 | 規格書設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/CustomValue/SelfSubmissionCustomValueSetting.vue'), },
              { name: 'mypage.company.setting-spec.partner.custom-value-setting', path: 'partners/custom-value-setting', meta: {title: '追加項目 | 関連事業者 | 規格書設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/CustomValue/PartnerIntraCustomValueSetting.vue'), },
              { name: 'mypage.company.setting-spec.partner-factory.custom-value-setting', path: 'partner-factories/custom-value-setting', meta: {title: '追加項目 | 関連事業者の工場 | 規格書設定 | マイページ'}, props: true,
                component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Setting/CustomValue/PartnerFactoryIntraCustomValueSetting.vue'), },
            ]
          },
        ]
      },
      { name: 'mypage.company.intra-category', path: 'intra-category', meta: {title: '自社カテゴリ設定 | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/IntraCategory/IntraCategory.vue'), },
      {
        path: 'approvals',
        component: () => import(/* webpackChunkName: "views/auth" */ '@/views/Router.vue'),
        children: [
          { name: 'mypage.company.approval', path: '/', meta: {title: '社内承認と稟議 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalHome.vue'), },
          { name: 'mypage.company.approval.flow.create.acceptance', path: 'flows/create/acceptance', meta: {title: '回収の承認ワークフロー作成 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalFlowEdit.vue'), },
          { name: 'mypage.company.approval.flow.create.owned', path: 'flows/create/owned', meta: {title: '自社規格書の承認ワークフロー作成 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalFlowEdit.vue'), },
          { name: 'mypage.company.approval.flow.edit', path: 'flows/:approvalFlowSettingId/edit', meta: {title: '承認ワークフロー編集 | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalFlowEdit.vue'), },
          { name: 'mypage.company.approval.flow.step.create', path: 'flows/:approvalFlowSettingId/steps/create', meta: {title: '承認ステップ編集 | 承認ワークフロー | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalFlowStepEdit.vue'), },
          { name: 'mypage.company.approval.flow.step.edit', path: 'flows/:approvalFlowSettingId/steps/:approvalFlowSettingStepId/edit', meta: {title: '承認ステップ編集 | 承認ワークフロー | マイページ'}, props: true,
            component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Approval/ApprovalFlowStepEdit.vue'), },
        ]
      },
      { name: 'mypage.company.members', path: 'members', meta: {title: 'メンバー管理 | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Member.vue'), },
      { name: 'mypage.company.invoice', path: 'invoices', meta: {title: '請求 | マイページ'}, props: true,
        component: () => import(/* webpackChunkName: "views/mypage" */ '@/views/common/mypage/company/Invoice.vue'), },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "views" */ '@/views/ResponsiveLayout.vue'),
    children: [
      { path: 'faq', name: 'faq.index', meta: {title: 'よくある質問'},
        component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Index.vue'), },
      { path: 'faq/:id', name: 'faq.show', props: true, meta: {skipE2E: true},
        component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Show.vue'), },
      { path: 'inquiry/new', name: 'inquiry.new', meta: {title: 'お問い合わせフォーム', skipE2E: true },
        component: () => import(/* webpackChunkName: "views/inquiry" */ '@/views/common/inquiry/New.vue'), },
      { path: 'inquiry/complete', name: 'inquiry.complete', props: true, meta: { skipE2E: true},
        component: () => import(/* webpackChunkName: "views/inquiry" */ '@/views/common/inquiry/Complete.vue'), },
    ]
  },
  {
    path: '/report/invoice', name: 'report.invoice', props: true, meta: { guard: 'auth', skipE2E: true  },
    component: () => import(/* webpackChunkName: "views/report" */ '@/views/label/report/InvoiceReport.vue'),
  },
];

export const errorRoutes = [
  {
    path: '500', name: '500',
    props: {
      title: '処理中にエラーが発生しました。',
      bodyText: 'お手数をおかけしますが、しばらく経ってから再度お試しください。'
    },
    component: () => import(/* webpackChunkName: "views/error" */ '@/views/common/error/Error.vue'),
  },
  { path: '*', name: '404', meta: {skipE2E: true},
    props: {
      title: '404：ページが見つかりません',
      bodyText: 'お探しのページが見つけることができませんでした。<br/><br/>お手数をおかけ致しますが、URLが間違っていないか、今一度ご確認ください。'
    },
    component: () => import(/* webpackChunkName: "views/error" */ '@/views/common/error/Error.vue'),
  },
];

export const routesForLabel = [
  {
    path: '/label',
    component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views" */ '@/views/Router.vue'),
    meta: {type: 'label'},
    children: [
      { name: 'add-subscription', path: 'add-subscription', meta: {skipE2E: true },
        component: () => import(/* webpackChunkName: "views" */ '@/views/label/AddSubscription.vue')},
      {
        path: '/', name: 'lp', meta: {title: "食品表示のカンタン自動作成システム！"},
        component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views/lp" */ '@/views/label/LP.vue'),
      },
      { path: 'static/privacy', name: 'static.privacy', meta: {title: 'プライバシーポリシー' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/label/static/PrivacyPolicy.vue'), },
      { path: 'static/terms', name: 'static.terms', meta: {title: '利用規約' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/label/static/Terms.vue'), },
      { path: 'static/business_law', name: 'static.business_law', meta: {title: '特定商取引法に基づく表記' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/label/static/BusinessLaw.vue'), },

      ...commonRoutes,
      {
        path: 'companies/:companyId/',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Layout.vue'),
        meta: { guard: 'auth', skipE2E: true },
        children: [
          { name: 'logged-in', path: 'logged-in', redirect: {name: 'home'}, meta: {skipE2E: true} },

          { name: 'home', path: 'home', meta: {title: 'ホーム'} , props: true,
            component: () => import(/* webpackChunkName: "views/ingredient" */ '@/views/label/companies/Home.vue'), },

          { name: 'ingredient.create', path: 'ingredients/create', alias: 'edit', meta: {title: '原材料作成'}, props: true,
            component: () => import(/* webpackChunkName: "views/ingredient" */ '@/views/label/companies/ingredient/Create.vue'), },
          { name: 'ingredient.edit', path: 'ingredients/:id/edit', props: true, meta: {title: '原材料編集'},
            component: () => import(/* webpackChunkName: "views/ingredient" */ '@/views/label/companies/ingredient/Create.vue'), },
          { name: 'ingredient.clone', path: 'ingredients/:id/clone', props: true, meta: {title: '原材料複製'},
            component: () => import(/* webpackChunkName: "views/ingredient" */ '@/views/label/companies/ingredient/Create.vue'), },
          { name: 'ingredient.index', path: 'ingredients', props: true, meta: {title: '原材料一覧'},
            component: () => import(/* webpackChunkName: "views/ingredient" */ '@/views/label/companies/ingredient/Index.vue'), },

          { name: 'preproduct.create', path: 'preproducts/create', meta: {title: '中間原材料作成'}, props: true,
            component: () => import(/* webpackChunkName: "views/preproduct" */ '@/views/label/companies/preproduct/Create.vue'), },
          { name: 'preproduct.edit', path: 'preproducts/:id/edit', props: true, meta: {title: '中間原材料編集'},
            component: () => import(/* webpackChunkName: "views/preproduct" */ '@/views/label/companies/preproduct/Create.vue'), },
          { name: 'preproduct.clone', path: 'preproducts/:id/clone', props: true, meta: {title: '中間原材料複製'},
            component: () => import(/* webpackChunkName: "views/preproduct" */ '@/views/label/companies/preproduct/Create.vue'), },
          { name: 'preproduct.index', path: 'preproducts', props: true, meta: {title: '中間原材料一覧'},
            component: () => import(/* webpackChunkName: "views/preproduct" */ '@/views/label/companies/preproduct/Index.vue'), },

          { name: 'product.create', path: 'products/create', meta: {title: '商品作成'}, props: true,
            component: () => import(/* webpackChunkName: "views/product" */ '@/views/label/companies/product/Create.vue'), },
          { name: 'product.edit', path: 'products/:id/edit', props: true, meta: {title: '商品編集'},
            component: () => import(/* webpackChunkName: "views/product" */ '@/views/label/companies/product/Create.vue'), },
          { name: 'product.clone', path: 'products/:id/clone', props: true, meta: {title: '商品複製'},
            component: () => import(/* webpackChunkName: "views/product" */ '@/views/label/companies/product/Create.vue'), },
          { name: 'product.index', path: 'products', props: true, meta: {title: '商品一覧'},
            component: () => import(/* webpackChunkName: "views/product" */ '@/views/label/companies/product/Index.vue'), },

          { name: 'label-print-setting.index', path: 'label-print-settings/', props: true, meta: {title: '印刷'},
            component: () => import(/* webpackChunkName: "views/print" */ '@/views/label/companies/print/Index.vue'), },

          // よくある質問
          { name: 'company.faq.index', path: 'faq', meta: {title: 'よくある質問'},
            component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Index.vue'), },
          { name: 'company.faq.show', path: 'faq/:id', props: true, meta: {skipE2E: true},
            component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Show.vue'), },
        ],
      },
      {
        name: 'print-label',
        path: 'companies/:companyId/print-label/:type/:labelSheetId/:manufactureDateTimestamp/:items', props: true,
        meta: { guard: 'auth', skipE2E: true, title: '印刷' },
        component: () => import(/* webpackChunkName: "views/print" */ '@/views/label/companies/print/Print.vue'),
      },
      ...errorRoutes,
    ]
  },
  {
    path: '*',
    redirect: to => {
      return '/label' + to.path; // queryも引き継がれる
    }
  }
];

function createSpecShowRoutes(prefix, title) {
  return [
    { name: `${prefix}.show`, path: '', meta: {title: `${title} - 基本`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Basic.vue'), },
    { name: `${prefix}.show.company`, path: 'company', meta: {title: `${title} - 企業`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Company.vue'), },
    { name: `${prefix}.show.ingredient`, path: 'ingredient', meta: {title: `${title} - 原材料`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Ingredient.vue'), },
    { name: `${prefix}.show.label`, path: 'label', meta: {title: `${title} - 一括表示`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Label.vue'), },
    { name: `${prefix}.show.nutrition`, path: 'nutrition', meta: {title: `${title} - 栄養成分`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Nutrition.vue'), },
    { name: `${prefix}.show.package`, path: 'package', meta: {title: `${title} - 包材`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Package.vue'), },
    { name: `${prefix}.show.manufacture`, path: 'manufacture', meta: {title: `${title} - 製造`},
      component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/components/spec-components/show/Manufacture.vue'), },
  ];
}

export const routesForSpec = [
  {
    path: '/spec',
    component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views" */ '@/views/Router.vue'),
    meta: {type: 'spec', titleSuffix: ' | スマート食品規格書'},
    children: [
      { name: 'add-subscription', path: 'add-subscription', meta: {skipE2E: true },
        component: () => import(/* webpackChunkName: "views" */ '@/views/spec/AddSubscription.vue')},
      {
        path: '/', name: 'lp', meta: {title: "規格書のカンタン作成が無料、管理も0円~ "},
        component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views/lp" */ '@/views/spec/LP.vue'),
      },
      { path: 'static/privacy', name: 'static.privacy', meta: {title: 'プライバシーポリシー' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/spec/static/PrivacyPolicy.vue'), },
      { path: 'static/terms', name: 'static.terms', meta: {title: '利用規約' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/spec/static/Terms.vue'), },
      { path: 'static/business_law', name: 'static.business_law', meta: {title: '特定商取引法に基づく表記' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/spec/static/BusinessLaw.vue'), },
      ...commonRoutes,
      {
        path: 'share-requests/:specShareRequestId/confirm',
        name: 'confirm-share-request',
        props: true,
        meta: { title: '提出リクエストの確認' },
        component: () => import(/* webpackChunkName: "views" */ '@/views/spec/confirm-share-request/ConfirmShareRequest.vue'),
      },
      {
        path: 'companies/:companyId',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Layout.vue'),
        meta: { guard: 'auth', skipE2E: true },
        children: [
          { name: 'logged-in', path: 'logged-in', redirect: {name: 'spec.index'}, meta: {skipE2E: true} },

          // 自社規格書
          { name: 'spec.create', path: 'specs/create', meta: {title: '規格書作成'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/create/Create.vue'), },
          { name: 'spec.create.import', path: 'specs/create/import', meta: {title: '規格書取り込み修正'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/create/Create.vue'), },
          { path: 'specs/:specId', props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/show/Show.vue'),
            children: [
              ...createSpecShowRoutes('spec', '規格書詳細'),
              { name: `spec.show.intra`, path: 'intra', meta: {title: `規格書詳細 - 管理情報`},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/show/Intra.vue'), },
            ]
          },
          { name: 'spec.edit', path: 'specs/:specId/edit', meta: {title: '規格書編集'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/create/Create.vue'), },
          { name: 'spec.edit.import', path: 'specs/:specId/edit/import', meta: {title: '規格書編集'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/create/Create.vue'), },
          { name: 'spec.clone', path: 'specs/:specId/clone', meta: {title: '規格書複製'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/create/Create.vue'), },
          { name: 'spec.index', path: 'specs', meta: {title: '規格書一覧'}, props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/specs/Index.vue'), },

          // 回収依頼
          { name: 'share-request.create', path: 'share-request/create', meta: {title: '回収依頼作成'}, props: true,
            component: () => import(/* webpackChunkName: "views/receives" */ '@/views/spec/companies/receives/create-request/CreateRequest.vue'), },
          { name: 'share-request.transfer', path: 'share-request/transfer', meta: {title: '回収依頼転送'}, props: true,
            component: () => import(/* webpackChunkName: "views/receives" */ '@/views/spec/companies/receives/create-request//CreateRequestTransfer.vue'), },

          // 回収中
          { name: 'receive.index', path: 'receives/status\::status?', meta: {title: '回収管理'}, props: true,
            component: () => import(/* webpackChunkName: "views/receives" */ '@/views/spec/companies/receives/Index.vue'), },
          { path: 'receives/:specShareId/submissions/:submissionId', props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/receives/show/Show.vue'),
            children: [
              ...createSpecShowRoutes('receive', '受領詳細'),
              { name: 'receive.show.intra', path: 'intra', meta: {title: '受領詳細 - 管理情報'},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/receives/show/Intra.vue'), }
            ]
          },
          // 回収済規格書
          { path: 'acceptances/:specAcceptanceId/acceptance-specs/:specAcceptanceSpecId', props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/acceptances/Show.vue'),
            children: [
              ...createSpecShowRoutes('acceptance', '回収規格書詳細'),
              { name: 'acceptance.show.intra', path: 'intra', meta: {title: '回収規格書詳細 - 管理情報'},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/acceptances/show/Intra.vue'), }
            ]
          },

          // 提出
          { name: 'submission.company.index', path: 'submission-companies', meta: {title: '提出管理 企業一覧'}, props: true,
            component: () => import(/* webpackChunkName: "views/submissions" */ '@/views/spec/companies/submissions/CompanyIndex.vue'), },
          { name: 'submission.index', path: 'submissions/status\::status?', meta: {title: '提出一覧'}, props: true,
            component: () => import(/* webpackChunkName: "views/submissions" */ '@/views/spec/companies/submissions/SubmissionList.vue'), },
          { name: 'submission.create', path: 'submissions/:specShareId/create', meta: {title: '規格書の作成・提出'}, props: true,
            component: () => import(/* webpackChunkName: "views/submissions" */ '@/views/spec/companies/submissions/Create.vue'), },
          { name: 'submission.revise', path: 'submissions/:specShareId/revise/:specId', meta: {title: '規格書の更新・提出'}, props: true,
            component: () => import(/* webpackChunkName: "views/submissions" */ '@/views/spec/companies/submissions/Create.vue'), },

          // セルフ提出
          { name: 'self-submission.create', path: 'self-submissions/create', props: true, meta: {title: '回収規格書（手入力）作成'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/create/Create.vue'), },
          { name: 'self-submission.create.import', path: 'self-submissions/create/import', props: true, meta: {title: '回収規格書（手入力）作成'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/create/Create.vue'), },
          { name: 'self-submission.edit', path: 'self-submissions/:selfSubmissionId/edit', props: true, meta: {title: '回収規格書（手入力）更新'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/create/Create.vue'), },
          { name: 'self-submission.clone', path: 'self-submissions/:selfSubmissionId/clone', props: true, meta: {title: '回収規格書（手入力）複製'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/create/Create.vue'), },
          { name: 'self-submission.edit.import', path: 'self-submissions/:selfSubmissionId/edit/import', props: true, meta: {title: '回収規格書（手入力）更新'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/create/Create.vue'), },
          { path: 'self-submissions/:selfSubmissionId', props: true,
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/show/Show.vue'),
            children: [
              ...createSpecShowRoutes('self-submission', '回収規格書（手入力）詳細'),
              { name: 'self-submission.show.intra', path: 'intra', meta: {title: '回収規格書（手入力）詳細 - 管理情報'},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/self-submissions/show/Intra.vue'), }
            ]
          },

          // 比較
          { name: 'spec-comparisons', path: 'spec-comparisons', props: true, meta: {title: '規格書比較'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/spec-comparisons/Comparison.vue'), },

          // 稟議
          { name: 'approvals.company', path: 'approvals/company/:status?', props: true, meta: {title: '組織全体の承認ワークフロー'},
            component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/approvals/CompanyApprovalRequestList.vue'), },
          {
            path: 'users/:userId',
            component: () => import(/* webpackChunkName: "views" */ '@/views/Router.vue'),
            meta: {guard: 'auth', skipE2E: true},
            children: [
              { name: 'approvals.requesting', path: 'approvals/requesting/:status?', props: true, meta: {title: 'あなたが申請した承認ワークフロー'},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/approvals/RequestingList.vue'), },
              { name: 'approvals.requested', path: 'approvals/requested/:status?', props: true, meta: {title: 'あなたの承認待ち'},
                component: () => import(/* webpackChunkName: "views/specs" */ '@/views/spec/companies/approvals/RequestedList.vue'), },
            ]
          },


          // よくある質問
          { name: 'company.faq.index', path: 'faq', meta: {title: 'よくある質問'},
            component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Index.vue'), },
          { name: 'company.faq.show', path: 'faq/:id', props: true, meta: {skipE2E: true},
            component: () => import(/* webpackChunkName: "views/faq" */ '@/views/common/faq/Show.vue'), },
        ],
      },
      ...errorRoutes,
    ]
  },
];
export const routesForAgent = [
  {
    path: '/agency',
    component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views" */ '@/views/Router.vue'),
    meta: {type: 'agency', titleSuffix: ' | スマート食品表示代行'},
    children: [
      {
        path: '/', name: 'lp', meta: {title: "食品表示ラベルの内容提案から印刷まで"},
        component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views/lp" */ '@/views/agency/LP.vue'),
      },
      {
        path: 'estimation', name: 'estimation', meta: {title: "見積もり"},
        component: () => import( /* webpackPreload: true */ /* webpackChunkName: "views/lp" */ '@/views/agency/Estimation.vue'),
      },
      { path: 'static/privacy', name: 'static.privacy', meta: {title: 'プライバシーポリシー' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/agency/PrivacyPolicy.vue'), },
      { path: 'static/business_law', name: 'static.business_law', meta: {title: '特定商取引法に基づく表記' },
        component: () => import(/* webpackChunkName: "views/static" */ '@/views/agency/BusinessLaw.vue'), },
    ]
  },
]

const routes = [
  ...routesForSpec,
  ...routesForLabel
];

export default routes;

export function getRouteRecursively(route, parentPath = '') {
  if (route.meta && route.meta.skipE2E) return [];

  if (route.children) {
    return route.children.flatMap(child => getRouteRecursively(child, route.path + '/'));
  } else {
    return {name: route.name, path: parentPath + route.path};
  }
}

<template>
  <div class="el-form-item no-optional u-col">
    <label v-if='label || $slots.label' class="el-form-item__label">
      <slot name="label">{{label}}</slot>
    </label>
    <div class="el-form-item__content">
      <div class="el-input" :class="{'el-input--small': size === 'small'}" >
        <div class="el-input__inner disabled"
             :style="{
               'justify-content': align === 'right' ? 'flex-end' : undefined,
             }">
          <template v-if="ellipsis">
            <div class="u-nowrap-scroll">
              <slot/>
            </div>
          </template>
          <template v-else>
            <div class="u-preline">
              <slot/>
            </div>
          </template>
        </div>
      </div>
      <div v-if='error' class="el-form-item__error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String, required: false
    },
    label: {
      type: String, default: ''
    },
    ellipsis: {
      type: Boolean, default: true,
    },
    error: {
      type: String, required: false,
    },
    align: {
      type: String, default: 'left', // left|right
    }
  },
};
</script>

<template>
  <header-component-wrapper>
    <template v-slot:logo>
      <router-link :to="getRoute($auth.isAuthenticated ? 'product.index': 'lp')" >
        <LogoLabel />
      </router-link>
    </template>

    <router-link :to="getRoute('home')" class="nav-item u-hover-opacity"
                 :class="{'active': active === 'home'}">{{$t('ホーム')}}</router-link>

    <router-link :to="getRoute('ingredient.index')" class="nav-item u-hover-opacity u-pl20 u-border-left"
                 :class="{'active': active === 'ingredient'}">{{$t('原材料')}}</router-link>
    <router-link :to="getRoute('preproduct.index')" class="nav-item u-hover-opacity"
                 :class="{'active': active === 'preproduct'}">{{$t('中間原材料')}}</router-link>
    <router-link :to="getRoute('product.index')" class="nav-item u-hover-opacity"
                 :class="{'active': active === 'product'}">{{$t('商品')}}</router-link>

    <router-link :to="getRoute('label-print-setting.index')" class="nav-item u-hover-opacity u-pl20 u-border-left"
                 :class="{'active': active === 'print'}">{{$t('ラベル印刷')}}</router-link>
  </header-component-wrapper>
</template>

<script>
  import HeaderComponentWrapper from "./HeaderComponentWrapper";
  import GetRouteMixin from "./GetRouteMixin";
  import LogoLabel from "@/components/Logo/LogoLabel";
  export default {
    mixins: [GetRouteMixin],
    components: {
      LogoLabel,
      HeaderComponentWrapper,
    },
    name: "HeaderComponentLabel"
  }
</script>

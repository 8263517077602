import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import parseISO from "date-fns/parseISO";
import _format from "date-fns/format";
import parse from "date-fns/parse";

class DateUtil {
  public formatDateRecursive(data:any, format:string = 'yyyy/MM/dd HH:mm:ss') {
    const isObject = o => o === Object(o);

    if (isDate(data)) {
      if (isValid(data)) {
        return formatDate(data as Date, format);
      } else {
        return '';
      }
    } else if (Array.isArray(data)) {
      return data.map(d => this.formatDateRecursive(d, format));
    } else if(isObject(data)) {
      return Object.keys(data).reduce((obj, key) => {
        return Object.assign(obj, {[key]: this.formatDateRecursive(data[key], format)});
      }, {});
    } else {
      // リテラル
      return data;
    }
  }
}

export function formatDate(
  date: string | Date,
  formatString = "yyyy/MM/dd"
): string {
  const isString = typeof date === "string" || date instanceof String;
  const src: Date = isString ? parseISO(date as string) : (date as Date);
  return _format(src, formatString);
}

export default new DateUtil();

<template>
  <HeaderComponentWrapper>
    <template v-slot:logo>
      <RouterLink :to="getRoute($auth.isAuthenticated ? 'spec.index': 'lp')" >
        <LogoSpec />
      </RouterLink>
    </template>
    <RouterLink :to="getRoute('receive.index')" class="nav-item u-hover-opacity"
                 :class="{'active': active === 'receive'}">{{$t('回収管理')}}</RouterLink>
    <RouterLink :to="getRoute('spec.index')" class="nav-item u-hover-opacity u-pl20 u-border-left"
                 :class="{'active': active === 'spec'}">{{$t('自社規格書')}}</RouterLink>
    <RouterLink :to="getRoute('submission.company.index')" class="nav-item u-hover-opacity"
                 :class="{'active': active === 'submission'}">{{$t('提出管理')}}</RouterLink>
  </HeaderComponentWrapper>
</template>

<script>
  import HeaderComponentWrapper from "./HeaderComponentWrapper";
  import GetRouteMixin from "./GetRouteMixin";
  import LogoSpec from "@/components/Logo/LogoSpec";
  export default {
    mixins: [GetRouteMixin],
    components: {
      LogoSpec,
      HeaderComponentWrapper,
    },
    name: "HeaderComponentSpec"
  }
</script>

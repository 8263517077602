import * as Sentry from "@sentry/browser";
import {AppTypes} from "@/app-types";
import ObjectUtils from "@/utils/object-utils";
import {CompanyRole} from "@/entities/user-entity";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import {PaymentChannel} from "@/repositories/company/payment-repository";
import {LangType} from "@/bootstraps/locale";

export class AuthClass {
  private _user:AuthUser|null = null;
  public get user():AuthUser {
    if (!this._user) throw new Error('ログアウトされています');
    return this._user;
  }

  public get isAuthenticated():boolean { return this._user !== null; }

  public login(user:AuthUser) {
    this._user = user;

    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id.toString(),
        username: user.name || ''
      });
    });

    return this;
  }

  public logout() {
    this._user = null;
  }
}
export default new AuthClass();

interface Payment {
  valid: boolean;
  paymentChannel: PaymentChannel;
  isPaymentMethodInvoice: boolean;
}

export class AuthUser {
  public id!:number;
  public displayLanguage!:LangType;
  public email!:string;
  public name!:string;
  public nameKana!:string;
  public company!:{id:number, name:string, uid:string};
  public companyRole!:CompanyRole;
  public isCardRegistered!:boolean;

  public canAccessAllDeptResources!:boolean;
  public companySetting!: {
    useIntraCategory:boolean,
    isENumberVisible:boolean,
    isDepartmentEnabled:boolean,
    isApprovalFlowEnabled: boolean,
    enableBrotherPrint: boolean;
  };

  public maxReadNotificationId:number = 0;

  public labelSubscription: Payment|null = null;
  public specSubscription: Payment|null = null;

  public isCompanyAdmin!: boolean;
  public isCompanyEditable!: boolean;

  public departments: CompanyDepartmentEntity[] = [];

  public constructor(init:AuthUser) {
    ObjectUtils.assignLiteralFields(this, init);
    this.company = init.company;
    this.companySetting = init.companySetting;
    this.labelSubscription = init.labelSubscription;
    this.specSubscription = init.specSubscription;
    this.departments = init.departments;
  }

  public get hasLabelSubscription(): boolean {
    return this.labelSubscription !== null;
  }
  public get hasValidLabelSubscription(): boolean {
    return this.labelSubscription !== null && this.labelSubscription.valid;
  }
  public get hasSpecSubscription(): boolean {
    return this.specSubscription !== null;
  }
  public get hasValidSpecSubscription(): boolean {
    return this.specSubscription !== null && this.specSubscription.valid;
  }
  public get hasAnySubscription(): boolean {
    return this.hasLabelSubscription || this.hasSpecSubscription;
  }

  public getSubscription(type:AppTypes): Payment|null{
    if(type === AppTypes.Label) return this.labelSubscription;
    if(type === AppTypes.Spec) return this.specSubscription;
    throw new Error('invalid parameter');
  }
}

import Vue from 'vue';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// vendors
import { RecycleScroller } from 'vue-virtual-scroller';
Vue.component('RecycleScroller', RecycleScroller);
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// vxe-table
import 'xe-utils'
import XEUtils from 'xe-utils';
import {
  VXETable,
  // Icon,
  Header,
  Column,
  Table
} from 'vxe-table';
import locale from 'vxe-table/lib/locale/lang/ja-JP';
VXETable.setup({
  // i18n: (key, args) => i18n.t(key, args),
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(locale, key), args)
});
Vue.use(Header);
Vue.use(Column);
Vue.use(Table); //最後


// Initialize Global Components
Vue.component('Pagination', () => import(/* webpackChunkName: "global-components" */ "@/components/Pagination.vue"));
Vue.component('Popover', () => import(/* webpackChunkName: "global-components" */ "@/components/Tooltip/TooltipHelp.vue")); // @deprecated
Vue.component('TooltipHelp', () => import(/* webpackChunkName: "global-components" */ "@/components/Tooltip/TooltipHelp.vue"));
Vue.component('ImageComponent', () => import(/* webpackChunkName: "global-components" */ "@/components/ImageComponent.vue"));
Vue.component('AddDeleteTable', () => import(/* webpackChunkName: "global-components" */ "@/components/Table/AddDeleteTable.vue"));

import FormItem from "@/components/Form/FormItem.vue";
import FormItemText from "@/components/Form/FormItemText.vue";
import InputText from "@/components/Form/InputText.vue";
import InputNumber from "@/components/Form/InputNumber.vue";
Vue.component('FormItem', FormItem);
Vue.component('FormItemText', FormItemText);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('InputThumbnailWide', () => import(/* webpackChunkName: "global-components" */ "@/components/Form/Thumbnail/InputThumbnailWide.vue"));
Vue.component('MultipleInput', () => import(/* webpackChunkName: "global-components" */ "@/components/Form/MultipleInput.vue"));

import FormItemDisabled from "@/components/Form/FormItemDisabled.vue";
Vue.component('FormItemDisabled', FormItemDisabled);

import OptimizedFilterableSelect from "@/components/FilterableSelect/OptimizedFilterableSelect.vue";
Vue.component('OptimizedFilterableSelect', OptimizedFilterableSelect);
import OptimizedFilterableSelectOption from "@/components/FilterableSelect/OptimizedFilterableSelectOption.vue";
Vue.component('OptimizedFilterableSelectOption', OptimizedFilterableSelectOption);

import StringFilterableSelect from "@/components/FilterableSelect/StringFilterableSelect.vue";
Vue.component('StringFilterableSelect', StringFilterableSelect);


// Filters
import formatDate from 'date-fns/format';
import isDate from 'date-fns/isDate';
import round from 'lodash/round';
import parseISO from "date-fns/parseISO";
import fromUnixTime from 'date-fns/fromUnixTime';
import ja from 'date-fns/locale/ja';
Vue.filter("formatDate", (s:string|Date, format = 'yyyy-MM-dd') => {
  if(!s) return '';
  const parsedDate = isDate(s) ? s as Date : parseISO(s as string);
  return formatDate(parsedDate, format, { locale: ja });
});
Vue.filter("round", round);
Vue.filter("parseISO", parseISO);
Vue.filter("fromUnixTime", fromUnixTime);
Vue.filter('toLocale', _ => _.toLocaleString());

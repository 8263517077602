import {Notification} from "element-ui";
import {i18n} from "@/bootstraps/locale";

export default class ApplicationUpdateWatcher {
  private CHECK_FREQUENCY_MINUTES = 10;
  private previousHash = null;

  private onChanged = () => {};

  public constructor(callback:any = null) {
    if (!callback) {
      callback = () => {
        Notification({
          title: i18n.t('アプリケーションが更新されました') ,
          dangerouslyUseHTMLString: true,
          message: i18n.t("画面の再読込を行ってください<br>（ここをクリックして再読込する）"),
          onClick: () => location.reload(),
          duration: 0,
        });
      };
    }
    this.onChanged = callback;
  }

  public startWatch(target:string) {
    const func = async () => {
      const res = await fetch(target);
      if (res.status !== 200) throw Error('Cannot access server.');

      const html = await res.text();
      const hash = this.createHash(html);
      if(this.judgeHash(hash)) {
        this.onChanged();
        return true;
      }
      return false;
    }

    func();

    const id = setInterval(async () => {
      const res = await func();
      if(res) {
        clearInterval(id);
      }
    }, 1000 * 60 * this.CHECK_FREQUENCY_MINUTES);
  };

  private createHash(str) {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  private judgeHash(hash){
    if (!this.previousHash) {
      this.previousHash = hash;
      return;
    }
    return this.previousHash !== hash;
  }
}

export default {
  validations: {
    "文字": "字符",
    "数値を入力してください": "请输入数值",
    "整数を入力してください": "请输入整数",
    "正の整数を入力してください": "请输入正整数",
    "日付を入力してください": "请输入日期",
    "日付形式を入力してください": "请输入日期格式",
    "値を入力してください": "请输入值",
    "この項目は必須です": "这个项目是必须的",
    tel: "请输入电话号码（例：01-2345-6789）",
    min: "请输入不超过{0}",
    max: "请输入不少于{0}",
    between: "输入值在{min}和{max}之间",
    decimal: {
      int: "整数部分必须是{0}个数字或更少",
      decimal: "小数部分必须是{0}个数字或更少",
      minDecimal: "小数部分必须是{0}个数字或更多"
    },
    prohibitedChars: "不能使用{0}",
  },
  auth: {
    signup: {
      agreement: "请同意{0}和{1}并点击以下按钮。",
    }
  },
  day: "天",
  days: "天",
  spec: {
    request: {
      confirm: {
        detail: "TODO",
      }
    },
    specs: {
      index: {
        search_placeholder: '按商品名称、联系人、标签、成分名称、添加剂、过敏原、产地、相关公司名称等进行搜索。',
        number_of_shared_companies: "共享企业数",
        annotation_for_filtered_by_dept: "只显示你当前所属部门的数据",
        annotation_for_filtered_by_dept_link: "点击这里获取更多信息",
      },
      detail: {
        level: "{n}阶组件",
        contamination_same_factory: "同一个工厂生产 '{allergens}'",
        contamination_same_line: "同一条生产线生产 '{allergens}'",
      },
      edit: {
        guarantee_text: `本規格書は、食品衛生法などの関係法令を遵守しており、記載事項を保証するものとします。<br/><br/>本規格書に関して、関連法令などに変更が生じる場合、速やかに通知を行い、再提出の必要がある場合は、これに応じるものとします。`,
      }
    },
    self_submission: {
      detail: {
        alert: "这个规格书是手动添加的。<br><br>" +
          "抄写错误以及采集和提交站点的更新没有实时反映，这可能导致食品事故。<br><br>" +
          "为了实时反映这一点，你需要让提交者安装'智能食品规格书'（免费），并在屏幕右上角的'采集管理'>'+新采集规格书'下采集。"
      }
    },
    receives: {
      detail: {
        display_history: "显示 {date} 的呈文",
        history: "显示 {date}",
      },
    },
    forwarding: {
      seller_replacement: "この時、「販売者」のみ「あなたの組織の情報」に差し替えられます。あなたの組織の最新情報は、{0}から確認することが可能です。",
    },
    common: {
      left_days: "剩余 {days} 天",
      over_days: "逾期{days}天",
      required_approvals: "需要其余{restRequiredApprovalUsersLength}个成员的批准，才能{typeLabel}本规格书。<br><br>" +
        "所需的批准人姓名是{userNames}。<br><br>" +
        "如果规格书的内容发生变化，批准也会被重置。",
      per: "每{n}克",
      action_by: "{action} by {by}",
      management_tag: "管理",
      displaying: "显示{item}",
      export_alert: '<ul>' +
        '<li>这个过程可能需要几十秒</li>' +
        '<li>我们已经确认，目前在设置某些添加剂等时，会出现错误。 这将在更新中得到解决。</li>' +
        '</ul>',
    },
  },
  mypage: {
    company: {
      setting: {
        acceptedSpecReconfirmationNotification: "通知后{0}至{1}天",
      },
      department: {
        delete_alert: `我不能删除这个部门，因为有些成员属于这个部门。<br><br>` +
          `要删除这个部门，请进入组织账户设置>"添加/管理成员"，并删除这个部门的所有成员。`,
        delete_confirm: '你真的想删除这个部门吗？<br><br>' +
          '如果你删除它，属于这个部门的数据可能不再对管理成员以外的任何人可见。',
      },
      member: {
        edit: '编辑 {0}',
        delete: '删减 {0}',
        invitation_sent: '发送邀请邮件.<br>' +
          '接收者可以通过点击你发送的电子邮件中的链接加入该组织。<br>' +
          '如果没有收到，请检查您的 "垃圾邮件文件夹 "或其他此类文件夹。',
      },
      invoice: {
        cancel_label: `如果你取消订阅，你将不能再查看注册的 "原材料" 和 "商品"。<br><br>` +
          `当你重新订阅时，你可以再次查看。<br><br>取消后，您可以继续使用服务，直到"{0}"。`,
        cancel_spec: `如果你取消订阅，你将不能再查看你注册的 "规格书"、"回收" 和 "提交"。<br><br>` +
          `当你重新订阅时，你可以再次查看。<br><br>取消后，您可以继续使用服务，直到"{0}"。`,
        canceled: `这个计划将停止在"{0}"。<br><br>从底部按'再契约'，你仍然能够像以前一样使用该计划。`,
        resume_label: `再次报名参加 "智能食品标示"。<br><br>可以查看和编辑已注册的 "原材料" 和 "商品"，也可以注册新的材料。`,
        resume_spec: `再次签约 "智能食品规格书"。<br><br>可以查看和编辑已注册的 "规格书"、"回收" 和 "提交"，也可以注册新的规格书。`,
        can_use_until: "可使用至{0}",
      }
    }
  },
  common: {
    of: "{action}{item}",
    select: "选择{item}",
    name: "{item}名称",
    gte: "{0]以上",
    lte: "{0]以下",
    search_result: `"{text}" 的搜索结果 > {total}项 ({count}项 显示)`,
    required: "必须",
    optional: "任意",
    append: "{0}",
    week: "{n}周",
    month: "{n}个月",
  },
  utils: {
    only_japanese: '',
  },
  "スマート食品規格書": "智能食品规格书",
  "スマート食品表示": "智能食品标示",
  "食品規格書": "食品规格书",
  "食品表示": "食品标签",
  "商品": "商品",
  "製品": "产品",
  "規格書": "规格书",
  "回収": "回收",
  "提出": "提交",
  "受領": "收到",

  "機能": "机能",
  "価格": "价格",
  "お問い合わせ": "咨询",
  "ログイン": "登陆",
  "無料で始める": "免费开始",
  "規格書を無料作成": "免费制作规格书",
  "カンタン回収": "并且轻松回收",
  "面倒な規格書の回収作業、更新をカンタンに管理。": "轻松管理繁琐的规格书的回收和更新。",
  "作成側も無料作成、取引先への通知もカンタンに。": "创作者可以免费制作并且简单快速通知对方。",
  "規格書を無料で作成": "免费制作规格书",
  "見やすく、分かりやすい操作で": "方便查看和理解的操作",
  "標準商品規格書（SSSP/2014）に対応": "对应标准商品规格书（SSSP/2014）",
  "取引先の導入で、紙のやりとりも不要に": "商业伙伴导入该系统后，便可无需纸张的来往",
  "オプションで原材料名・栄養計算を自動で": "选择原材料名称后，将自动生成原材料成分",
  "規格書の回収もラクラク": "规格书的回收也非常轻松",
  "紙や電話は不要！必要な情報を簡単に": "无需纸张和电话！可以轻松得到必要的情报",
  "紙や電話のやりとりが不要に": "无需纸张和电话的往来",
  "不足情報もカンタン収集": "不足的信息也可简单收集",
  "更新があればすぐに把握": "可以立即知道是否有更新",
  "すごいスピードで": "导入者正在以",
  "導入が増えています！": "惊人的速度增加！",
  "“取引先に規格書の提出を求められたのですが、簡単に作れるだけでなく、オプションで食品表示・栄養成分も自動計算することができ、スムーズに提出できました。”": "“商业伙伴要求我提交规格书，它不仅容易制作，还能在选项中自动计算食品显示标签和营养成分，让我能够顺利提交规格书。”",
  "HAさん / 41歳 / 福岡 / 食品メーカー": "HA先生 / 41岁 / 福冈 / 食品制造商",
  "“このシステムを導入してから、規格書のやりとりにかける手間が大幅に削減されました。”": "“自从引入该系统以来，花在规格书上的时间和精力大幅减少了。”",
  "SYさん / 61歳 / 東京 / 食品卸": "SY先生 / 61岁 / 东京/ 食品流通",
  "“規格書が更新されるたびに紙を受け取っていたのですが、これは自動で送られてくるので、非常に助かります。”": "“每次规格书更新时我都会收到一张纸，但它是系统自动生成的，让我非常省力”",
  "AOさん / 45歳 / 東京 / 食品メーカー": "AO先生 / 45岁 / 东京/ 食品制造商",
  "コンプライアンスを意識する": "具有合规意识的企业",
  "事業者様の間で導入が進んでいます": "正越来越多地采用它们",
  "コンプライアンスを": "具有合规意识的",
  "意識する事業者様の間で": "企业正越来越",
  "導入が進んでいます": "多地采用它们",
  "動作環境": "动作环境",
  "Windows・Macで使えます。": "可以使用Windows/Mac。",
  "複雑な設定は、必要ありません。「今スグ」使えます。": "无需复杂的设定。「现在马上」就可以使用。",
  "動作保証": "运行保证",
  "Windows Edge": "谷歌浏览器",
  "動作保証外": "保修保证外",
  "Mozilla Firefox": "火狐浏览器",
  "Safari": "野生动物园",
  "Internet Explorer": "Internet Explorer",
  "それぞれ最新である必要があります。": "每个都必须是最新的。",
  "「Google Chrome」は、世界でもっとも使われているブラウザです。": "“谷歌浏览器”是世界上使用最多的浏览器。",
  "現在は保証外をお使いでも、コチラから3分程度で動作保証の導入が可能です。": "即使您目前不在保修范围内，您可以在3分钟内从这里导入运行保证。",
  "登録する「商品・原材料」の数によって自動で最適な価格になります。": "将根据所输入的“产品/原材料”的数量自动生成最优惠的价格。",
  "他社サービスより「リーズナブル」な導入を目指します。": "我们的目标是提供比其他公司更“合理”的服务。",
  "回収管理": "回收管理",
  "規格書作成": "制作规格书",
  "アカウント": "账号",
  "フリー": "自由",
  "規格書の無料作成と、回収を始めたい方": "想要免费制作规格书，开始回收的用户",
  "無料": "免费",
  "さっそく始める": "马上开始",
  "15 枚まで": "15张为止",
  "無制限": "无限制",
  "※上限あり": "※有上限",
  "スターター": "初始方案",
  "規格書の無料回収、規格書回収量が少量の方": "免费回收规格书，适合规格书回收量少的用户",
  "2,480円/月": "2,480日元/月",
  "/月": "/月",
  "気軽に相談": "欢迎咨询",
  "75 枚まで": "75张上限",
  "スタンダード": "标准方案",
  "規格書の無料回収、規格書回収が中規模の方": "免费回收规格书，适合规格书回收量中等规模的用户",
  "4,980円/月": "4,980日元/月",
  "150 枚まで": "150张上限",
  "プレミアム": "高级方案",
  "規格書の無料回収、規格書回収量が大量の方": "免费回收规格书，适合规格书回收量较大的用户",
  "9,800円/月": "9,800日元/月",
  "300 枚まで": "300张上限",
  "エンタープライズ": "企业",
  "規格書の回収が非常に多くある大企業向け": "面向规格书的回收量非常多的企业",
  "要相談": "需商谈",
  "共通機能": "共通机能",
  "規格書の作成": "制作规格书",
  "標準商品規格書（SSSP/2014）対応": "对应标准商品规格书（SSSP/2014）",
  "オプション）原材料名・栄養の自動計算": "选项）原材料名称/营养的自动计算",
  "規格書の提出管理": "规格书的提交管理",
  "企業ごとの規格書提出": "各个企业的规格书提交",
  "規格書の更新通知": "规格书的更新通知",
  "規格書の回収管理": "规格书的回收管理",
  "規格書ごとの回収管理": "根据每个规格书进行回收管理",
  "不足情報の連絡": "不足信息的联络",
  "その他のオプション": "其他的选项",
  "「他システムと連携して欲しい。」「食品表示の計算が面倒」": "“希望可以与其他系统进行联合。” “食品显示标签的计算很麻烦。”",
  "そんなご要望にお応えします。お気軽にご相談ください。": "我们会回应您的要求。 欢迎与我们联系。",
  "規格書に加えて『スマート食品表示』も契約することで、カンタンに原材料名・栄養成分の自動計算ができます。": "如果在规格书之外再订阅“智能食品标示”，您就可以轻松地自动计算原材料名称和营养成分。",
  "規格書をやりとりすると、後はレシピ入力だけで原材料名・栄養成分の計算が可能です。": "提交规格书后，只需输入配方即可计算原材料名称和营养成分。",
  "『スマート食品表示』へ": "前往「智能食品标示」",
  "入力代行": "代理输入",
  "詳細はこちら": "更多信息",
  "やっていたくだことは、書類を揃えていただくだけ！": "您所要做的，就只有准备文件！",
  "あなたの代わりにデータを作成します。": "为您代理数据的制作",
  "ご希望の書類の作成": "创建所需的文件",
  "ご利用中の「基幹」システムとの連携": "与您正在使用的“核心”系统进行联合",
  "ご利用中の「他社」システムとの連携": "与您正在使用的“其他公司”系统进行联合",
  "その他、お気軽にご相談ください。": "其他，欢迎咨询。",
  "気軽に相談する": "欢迎咨询",
  "さぁ！これで手間が": "来吧！",
  "かかる紙の規格書とは": "告别耗时耗力的",
  "おさらば！": "纸张规格书！",
  "さっそく試す": "马上试用",
  "サービスを知る": "了解服务",
  "利用者の声": "用户的声音",
  "もっと知る": "进一步了解",
  "よくある質問": "经常出现的提问",
  "利用規約など": "利用规约等",
  "利用規約": "利用规约",
  "プライバシーポリシー": "隐私政策",
  "特定商取引法に基づく表記": "基于特定商业交易法规定的标示",
  "関連サービス": "相关服务",
  "スマート食品表示代行": "智能食品标示代行",
  "©2021 スマート食品表示": "©2021 智能食品标示",
  "たった5分で新規作成": "只需5分钟就能创建一个新的",
  "無料で始めることができます。": "你可以免费开始。",
  "既に同じ組織の方がアカウントを持っている場合、下記は入力せず、管理メンバーから招待メールを受け取ってください。": "如果同一组织的人已经有一个账户，请不要输入以下内容，并收到管理成员的电子邮件邀请。",
  "メールアドレス": "邮箱",
  "必須": "必须",
  "普段お使いのメールアドレス": "您常用的电子邮件地址",
  "この項目は必須です": "这个项目是必须的",
  "利用規約 と プライバシーポリシー に同意の上、以下のボタンから作成してください。": "请同意使用条款和隐私政策，并点击下面的按钮来创建您的账户。",
  "以上で作成": "由超过",
  "アカウントを既にお持ちの方はコチラ": "已经有了一个账户？",
  "メールを送信しました": "发送的电子邮件",
  "本人確認のためにメールを送信しました。届いたメールに書かれているアドレスを開いてください。": "已发送电子邮件以确认您的身份。请打开你收到的电子邮件中的地址。",
  "本人確認完了": "身份验证完成",
  "メールアドレスを確認いたしました。パスワードおよび所属する組織とあなたの情報を入力してください。": "我们已经验证了您的电子邮件地址。请输入你的密码和你的组织以及你的详细资料。",
  "パスワード": "密码",
  "8文字以上の英数字": "8个字符以上的英数字",
  "組織名（屋号・法人名など）": "组织名称（如商号、公司名称）",
  "株式会社スマート": "SMART公司",
  "ご連絡先（電話番号）": "联系方式（电话号码）",
  "例）01-2345-6789": "例）01-2345-6789",
  "あなたの名前": "您的姓名",
  "お名前": "您的姓名",
  "例）須磨都 太郎": "例）须磨都 太郎",
  "須磨都 太郎": "例）须磨都 太郎",
  "次へ": "下一步",
  "登録が完了しました！": "您现在已经注册了!",
  "さっそく管理画面に移動して、あなたの商品を管理しましょう。": "快速进入管理页面，管理你的产品。",
  "さっそく使う": "立即使用",
  "以下の情報を入力して、ログインしてください。": "请在下面输入你的详细资料以登录。",
  "パスワードをお忘れの方はこちら": "忘记密码？",
  "アカウントをお持ちでない方はコチラ": "没有账户？",
  "パスワード再設定メールの送信": "发送密码重置电子邮件",
  "ご登録いただいたメールアドレスにパスワード再設定用のアドレスをお送りします。": "一个密码重置地址将被发送到您注册的电子邮件地址。",
  "ご登録時のメールアドレス": "你在注册时的电子邮件地址",
  "メールアドレスを入力してください": "输入你的电子邮件地址",
  "ログイン画面へ戻る": "< 返回到登录界面",
  "確認メールが送信されました": "已发送确认邮件",
  "送信": "传动",
  "パスワードの再設定": "密码重设",
  "メールに記載されているアドレスから、パスワードを再設定してください。": "从电子邮件中给出的地址重设密码。",
  "パスワード再設定": "密码重置",
  "パスワード再設定メールが送信されました": "已发送密码重置电子邮件",
  "下記を入力して、パスワードを再設定してください。": "输入以下内容来重设密码",
  "新しいパスワード": "新的密码",
  "新しいパスワード（確認）": "新的密码（确认）",
  "パスワードの再設定が完了しました！": "您的密码已被重新设置!",
  "新しく設定したパスワードでログインしてください。": "请用你新设置的密码登录。",
  "自社規格書": "公司内部规格书",
  "提出管理": "提交管理",
  "としてログイン": "登录为",
  "表示言語の変更": "改变显示语言",
  "表示言語の選択": "选择显示语言",
  "システムの表示言語を変更することができます。": "可以改变系统的显示语言。",
  "言語の選択": "语言选择",
  "言語を変更しました。画面を再読み込みしてください": "语言已更改。请重新加载页面",
  "閉じる": "贴近",
  "以上で変更": "变化幅度超过了",
  "個人アカウント設定": "个人账户设置",
  "ログアウト": "登出",
  "所属している組織": "你所属的组织",
  "組織ID": "组织ID",
  "取引先から提出依頼を受ける際にこちらのIDを指定する必要があります": "在收到供应商的提交请求时，您需要指定这个ID",
  "組織アカウント設定": "组织账户设置",
  "請求情報": "账单信息",
  "プロフィール": "概况",
  "個人プロフィール": "个人简介",
  "こちらから、名前等を変更することができます。": "您可以在这里更改名字等。",
  "名前": "姓名",
  "名前（フリガナ）": "姓名（假名）",
  "フリガナ": "假名",
  "例）スマト タロウ": "例）",
  "生年月日": "生年月日",
  "任意": "任意",
  "年 / 月 / 日": "年/月/日",
  "変更を保存": "保存变更",
  "変更したいメールアドレスを入力し、下記ボタンを押してください。届いたメールから承認を行うと、登録されているメールアドレスが変更されます。": "输入您要更改的电子邮件地址，然后按下面的按钮。从收到的电子邮件中进行认证后，登记的电子邮件地址将被更改。",
  "変更メールを送信": "发送变更邮件",
  "こちらから、パスワードを変更することができます。": "您可以在这里更改密码。",
  "現在のパスワード": "现在的密码",
  "組織プロフィール": "公司简介",
  "この画面は、管理メンバーのみに表示されています。組織の名前や電話番号などを変更することができます。「食品表示」や「規格書」などに自動で挿入されるため、正確な情報を入力ください。またすでに送信した規格書には、変更は反映されません。":
    "这个屏幕只对管理成员可见。你可以改变你的组织的名称和电话号码。请确保你输入正确的信息，因为它将自动插入 '食品标签 '和 '标准 '部分。另外，变化不会反映在已经发送的标准中。",
  "ID": "ID",
  "組織名": "公司名称",
  "例）株式会社スマート": "如：Smart有限公司",
  "組織名（カタカナ）": "公司名称（假名）",
  "例）カブシキガイシャスマート": "如：Kabushiki Kaisha Smart",
  "組織のメールアドレス": "公司的邮箱地址",
  "例）sample＠mail.com": "例）sample＠mail.com",
  "組織の電話番号": "公司的电话号码",
  "組織のFAX番号": "公司的传真号码",
  "組織のホームページURL": "公司主页的URL",
  "例）https://xxxx.xx.xx": "例）https://xxxx.xx.xx",
  "所在地": "所在地",
  "例）日本": "例）日本",
  "例）000-0000": "例如：000-0000",
  "例）都道府県": "例）都道府県",
  "例）市区町村番地": "如：城市/镇/村的地址",
  "例）建物": "大楼",
  "PL保険加入": "PL保险加入",
  "規格書設定": "规格书制定",
  "この画面で設定をすると、組織内の全員に同じ設定が適用されます。": "在此页面上进行设置时，此设置将适用于公司全员。",
  "連携（スマート食品表示）": "联系（智能食品标示）",
  "スマート食品規格書で回収・作成した原材料を自動でスマート食品表示の原材料に追加する。": "在智能食品规格书中收集和创建的原材料将自动添加到智能智能食品标示的原材料中。",
  "スマート食品表示から該当商品の栄養成分を引用する際、「Tr」（極微量）は「0」（ゼロ）として処理する。": "在引用智能食品标示中相关产品的营养成分时，'Tr'（非常小的量）被当作'0'（零）处理。",
  "社内承認と稟議": "内部批准和通知",
  "「自社規格書」に必要な「承認者」の選択": "对 '自己的规格书 '需要选择 '批准人'",
  "既存メンバーから選択": "从现有成员中选择",
  "メンバー削除": "删除成员",
  "をメンバーから削除しますか？": "你想把 {0} 删除为成员吗？",
  "の招待を取り消しますか？": "你想取消 {0} 的邀请吗？",
  "「回収規格書」に必要な「承認者」の選択": "选择 '回收规格书' 所需的 '批准人'",
  "組織全体の設定": "整个组织的设置",
  "部署管理": "部门管理",
  "部署の設定": "部门设置",
  "部署間のデータの閲覧・編集権限": "部门间的数据的查看和编辑权限",
  "添加物を入力する際の「E番号」表示": "输入添加剂时的'E-编号'指标",
  "回収・提出先": "回收/提交方",
  "こちらから、自社が規格書の回収または提出している企業の情報を閲覧・編集することができます。": "在这里，你可以查看和编辑由贵公司收集或提交规格书的公司信息。",
  "追加する部署名を入力して選択してください": "请输入并选择添加的部门名称",
  "追加する部署名を入力して選択してください。": "请输入并选择添加的部门名称",
  "この操作をする権限がありません": "没有此权限",
  "未分類": "未分类",
  "企業名": "企业名称",
  "都道府県": "都道府県",
  "TEL": "电话号码",
  "FAX": "传真",
  "PL加入": "加入PL",
  "データなし": "没有信息",
  "販売者": "销售商",
  "製造者": "制造商",
  "加工者": "加工商",
  "輸入者": "进口商",
  "提出元（回収手入力用）": "提交方（用于回收手动输入）",
  "関連事業者": "相关企业",
  "こちらから、この組織が取引している「製造者」「加工者」「輸入者」などを管理することができます。登録した情報は「食品表示」や「規格書」を入力する際に、入力補助として使用することができます。": "在这里，你可以管理与该组织打交道的 '制造商'、'加工者'、'进口商 '等。 在输入 '食品标签 '和 '标准 '时，注册信息可作为输入辅助工具。",
  "製造所": "工厂",
  "加工所": "加工厂",
  "メンバー追加・管理": "成员追加/管理",
  "この画面は、管理メンバーのみに表示されています。こちらから、この組織に所属している方を追加・編集・削除することができます": "这个屏幕只对管理成员可见。你可以在这里添加、编辑或删除属于这个组织的人",
  "メンバー一覧": "成员一览",
  "＋メンバーの追加": "成员的追加",
  "新しいメンバーを追加": "增加新成员",
  "以下を入力して「招待」を押してください。": "输入以下内容并按 '邀请'。",
  "例）test@mail.com": "例如：test@mail.com",
  "権限": "权限",
  "キャンセル": "取消",
  "以上で招待": "邀请函的内容超过了",
  "への参加": "参与",
  "下記を入力して、組織に参加してください。": "通过输入以下内容加入该组织。",
  "さっそく管理画面に移動しましょう。": "让我们迅速转到管理页面。",
  "管理画面に移動": "转到管理界面",
  "メンバー名": "成员名",
  "登録日": "登记日期",
  "の権限・部署を変更する": "改动权力和部门的",
  "をメンバーから削除": "从成员中删除",
  "招待済のメンバー一覧": "须磨都太郎",
  "招待日": "邀请日",
  "請求": "收费",
  "この画面は、管理者のみに表示されています。こちらでは、現在ご契約のプランの確認や、過去の利用詳細を確認することができます。": "此页面仅对管理员可见。在这里，您可以查看您当前签约的方案和过去使用的详细信息。",
  "スタータープラン": "原始方案",
  "スタンダードプラン": "标准方案",
  "プレミアムプラン": "高级方案",
  "メンドウな法律の原材料名や、": "这条法律使人们很容易找到成分的名称、",
  "栄養成分の計算をカンタンに！": "并计算出它们的营养含量!",
  "もう一つのサービス。無料期間あります。": "另一项服务。有免费期。",
  "もう一つのサービス。今なら無料プランあります。": "另一项服务。 现在有一个免费计划。",
  "詳細はコチラ": "欲了解更多信息，请点击这里",
  "次回請求:": "下回收费日:",
  "解約": "解约",
  "フリープラン": "自由方案",
  "0円": "0日元",
  "お支払い方法": "支付方法",
  "(登録されていません)": "(未登记)",
  "カード切替": "更换支付卡",
  "請求日": "收费日期",
  "サービス": "服务",
  "種類": "种类",
  "請求額": "收费金额",
  "領収書": "(收据",
  "お支払い": "支付",
  "※ 利用料金は各契約期間の初日に請求され、お客様のアカウントに反映されるまで数日かかることもございます。": "* 使用的费用将在每个合同期间的首日收取，可能需要几天时间才能反映到您的帐户中。",
  "商品名・担当者・タグ・原材料名・添加物・アレルゲン・原産地・関連企業名などで検索": "按商品名称、联系人、标签、成分名称、添加剂、过敏原、产地、相关公司名称等进行搜索",
  "出力": "出口",
  "手動追加": "手动追加",
  "回収規格書の手入力（作成）": "手动输入（准备）回收规格书",
  "回収規格書の手入力": "手动输入回收规格书",
  "以下で保存": "保存以下内容",
  "回収規格書（手入力）の保存": "回收规格书的存储（手动输入）",
  "下記を入力して保存してください。": "输入并保存以下内容。",
  "提出元の組織の選択": "选择提交文件的组织",
  "新規": "新建",
  "提出元の組織": "提交文件的组织",
  "提出元の組織名": "提交文件的组织名称",
  "例）株式会社スマート食品": "SMART食品株式会社",
  "提出元の組織名（全角カタカナ）": "提交文件的组织名称",
  "例）カブシキガイシャスマートショクヒン": "如：Kabushiki Kaisha Smart Shokuhin",
  "例）000−0000": "例）000−0000",
  "市町村番地": "市町村番地",
  "建物": "建筑物名称",
  "電話番号": "电话号码",
  "例）03-1234-5678": "例如：03-1234-5678",
  "FAX番号": "传真号码",
  "公式サイトURL": "网页地址",
  "例）https://smashoku.com": "例如：https://smashoku.com",
  "自社管理コード": "自我管理代码",
  "例）1001": "例子）1001",
  "仕入れ税抜単価（円）": "不含购买税的单价（日元）",
  "例）1300": "例如：1300",
  "自社の担当者": "内部人员",
  "戻る": "返回",
  "以上で保存": "节省的时间超过",
  "規格書の新規回収依頼": "要求收集新的规格书",
  "規格書の新規回収": "新的规格书集",
  "以下で依頼": "要求的是",
  "規格書の提出依頼": "要求提交规格书",
  "規格書の提出依頼を送信します。よろしいですか？（すぐに事業者に送信されます）": "你正在发送一个提交规格书的请求。你确定吗？(将被立即发送至运营商)",
  "依頼先": "联系信息（用于查询）（如公司电话）",
  "依頼先（不明な場合は右記？マークを確認）": "申请机构（如果不知道，见右图？标记)",
  "依頼先の組織ID": "申请机构的组织ID",
  "新しい組織に依頼": "向新的组织申请",
  "履歴": "历史",
  "例）ACXY2345": "例如：ACXY2345",
  "「メール」で新しい組織に依頼": "向新的组织申请“电子邮件”",
  "「組織ID」で新しい組織に依頼": "向新的组织申请“组织ID”",
  "依頼先のメールアドレス": "申请机构的电子邮件地址",
  "依頼期限": "申请期限",
  "例）2022/05/12": "例如：2022/05/12",
  "依頼時のコメント": "投入使用时的评论",
  "例）お世話になっております。スマート食品の須磨都です。下記商品の規格書作成をお願いいたします。": "例子。我的名字是Suma Miyako，来自Smart Foods。我们希望你能为以下商品准备一份规范。",
  "回収を依頼したい商品": "您希望要求收取的货物",
  "商品名・商品番号など依頼先に商品内容が分かるもの": "商品的名称和编号，或其他能向请求方显示产品内容的信息",
  "例）小麦": "如：小麦",
  "規格書の入力必須事項": "对规格书的强制性投入",
  "依頼先に最低限の情報以外で、必ず入力して欲しい項目を選んでください。": "请选择您希望请求者填写的字段，而不是最低限度的信息。",
  "必須項目を選択（複数選択可）": "选择一个必要的字段（可多选）",
  "選択してください": "请进行选择",
  "お取引先「{0}」から、下記の規格書の提出依頼が届いています。": "来自您的客户“{0}”的下列规格书提交请求已经到达。",
  "依頼があった商品": "请求的商品",
  "希望する提出期限": "希望的提交期限",
  "ログイン中のアカウントで提出": "用登录的账户提交",
  "ログイン中のアカウント": "登录的账户",
  "上記のアカウントで提出": "提交",
  "依頼されたメールアドレスは既に登録されています。ログインしてください": "请求的电子邮件地址已经注册。请登录。",
  "アカウントを作成して提出": "创建账户并提交",
  "既に同じ組織の方がアカウントを持っている場合、下記は入力せず、先に同じ組織の管理者から招待を受けてください。": "如果已经有同一组织的人拥有账户，请不要输入下列信息，而是先接受来自同一组织的管理员的邀请。",
  "ログインして提出": "登录并提交",
  "この提出依頼は、すでにログイン中の組織で受付済みです。": "此提交请求已经被登录的组织接受。",
  "この提出依頼は、すでに別の組織で受付されています。": "此提交请求已经被其他组织接受。",
  "依頼された商品を受付しました": "已接受请求的商品",
  "ユーザーが作成され、依頼された商品を受付しました。続いて規格書を入力してください。": "用户已创建，已接受请求的商品。请继续输入规格书。",
  "ログインし、依頼された商品を受付しました": "登录并接受请求的商品",
  "他": "其他",
  "すべて": "全部",
  "中断された取引": "中断的交易",
  "更新": "更新",
  "商品名": "商品名称",
  "一般名称": "通用名称",
  "タグ": "标签",
  "希望提出期限": "期望的提交截止日期",
  "回収先": "回收地址",
  "最終コメント": "最后的评论",
  "自社担当者": "内部负责人",
  "仕入れ金額（税抜）": "购买价格（不含税）",
  "中　断": "悬挂",
  "手動": "手动操作",
  "更新有": "可更新",
  "提出分を表示中": "显示提交的会议记录",
  "依頼文・コメント": "要求的文本/评论",
  "依頼文・コメントの履歴": "请求信和评论的历史",
  "依頼時からのコメント履歴を読むことができます。": "你可以阅读从请求时起的评论历史。",
  "コメント履歴": "评论历史",
  "日付": "日期",
  "ステータス": "状态",
  "差戻し（再提出の依頼）": "返回（要求重新提交）",
  "受領した規格書に納得がいかない場合、「差戻し」（再提出を依頼）をすることができます。": "如果你对你收到的规格书不满意，你可以 '送回'（要求重新提交）。",
  "担当者部署": "负责的部门",
  "品質管理部": "质量控制部",
  "添付コメント": "附上评论",
  "自由入力": "自由输入",
  "以上で差戻し": "请将上述内容转回",
  "問題がないので受領する": "收到时没有任何问题",
  "問題が無いので受領する": "收到时没有任何问题",
  "以下で受領すると、差戻しはできなくなります。足りない情報がある時は、他の連絡手段により、規格書の発行者に情報を追加してもらってください。": "请收到时，不能再次送回。如果你缺少信息，请使用其他途径追加信息。",
  "以上で受領": "收到",
  "問題がないことを最終確認して受領": "收到时没有任何问题",
  "MerQurius原料品質規格書（v3.1）フォーマット（Excel）の出力": "MerQurius原材料质量规范（v3.1）格式（Excel）输出",
  "規格書を「MerQurius原料品質規格書（v3.1）フォーマット（Excel）」で出力します。出力にあたっては、以下をご確認ください。": "以'MerQurius原材料质量规格书（v3.1）格式（Excel）'输出规格书。关于输出，请检查以下内容。",
  "ダウンロードには、数十秒かかることがございます。": "下载可能需要几十秒。",
  "現在一部の添加物等が設定されている場合、エラーが発生することを確認しております。これについては、アップデートにて対応予定です。": "我们已经确认，目前在设置某些添加剂等时，会出现错误。这将在更新中得到解决。",
  "このフォーマットで提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。":
    "如果你以这种格式提交，你需要在每次更新规格书时对提交者进行管理，因此我们建议你让提交者实施智能食品规格书，然后在系统中发送。",
  "出力する食品規格書": "输出食品规格书",
  "MerQurius Net ID": "MerQurius Net ID",
  "例）R12345000001-001": "例如：R12345000001-001",
  "以上で出力": "上面的输出",
  "食品規格書の比較": "食品规格的比较",
  "規格書の比較": "规格的比较",
  "この規格書と比較したい、比較先の規格書を選んで下さい。別ウィンドウで比較が表示されます。": "选择你希望与之比较的规格书和你希望与之比较的规格书。比较结果将出现在一个新窗口。",
  "比較元の規格書": "比较源规格书",
  "比較先の規格書": "比较者的规格书",
  "以上で比較": "与上文比较",
  "管理情報（相手方には表示されません）": "管理信息（不显示给对方）",
  "自社管理情報": "自己的管理信息",
  "自社管理用メモ": "给自己的管理部门的备忘录",
  "自社管理用メモ（相手には公開されません）": "给自己的管理部门的备忘录（不显示给对方）",
  "更新ログ": "更新日志",
  "管理情報を変更": "变化管理信息",
  "例）1712": "例如：1712年",
  "選択または自由入力で作成": "选择或者自由输入",
  "選択または自由入力で作成（複数）": "选择或者自由输入（多个）",
  "検索または自由入力で作成": "搜索或者自由输入",
  "追加項目の有無": "追加项目的有无",
  "追加項目名": "附加项目名称",
  "項目に対する値": "值",
  "削除": "删减",
  "複製": "繁殖",
  "＋ 規格書の作成": "=+ 制订规格书",
  "ブランド名": "品牌名　",
  "共有先": "共享地址",
  "最終更新日": "最终更新日期",
  "最終更新者": "最终更新者",
  "を表示中": "显示",
  "下書き": "稿件",
  "下書き分": "稿件",
  "編集": "编辑",
  "配信": "交付",
  "管理情報": "管理信息",
  "基本情報": "基本情况",
  "自社規格書タグ": "内部规格书标签",
  "担当者": "负责人",
  "選択": "选择",
  "下書き保存": "保存为草稿",
  "保存して配信": "保存并交付",
  "正規の版として保存": "保存为正式版",
  "正規の版として提出": "提交为正式版",
  "基本": "基本",
  "企業": "公司",
  "原材料": "原材料",
  "一括表示": "食品标签",
  "栄養": "营养",
  "栄養成分": "营养成分",
  "栄養成分表示": "营养成分",
  "包材": "包装材料",
  "製造": "制造",
  "既存規格書の引用": "对现有规格书的引用",
  "引用の有無": "引用",
  "正式商品名": "商品正式名称",
  "例）スマート印 マヨネーズ": "例如，智能标记蛋黄酱",
  "正式商品名（全角カタカナ）": "官方商品名称",
  "例）スマートモリアワセ": "youhuipinpan",
  "食品区分": "食品区分　",
  "JICFS分類": "JICFS分类 ",
  "メーカー名": "制造商名称",
  "メーカー管理コード": "制造商的控制代码",
  "発売日": "发售日　",
  "例）日付を選択": "例如：选择日期",
  "適用日": "适用日",
  "規格書適用日": "适用日",
  "最終受領日": "最终收到日期",
  "日付を選択": "选择日期",
  "商品情報URL": "商品信息URL",
  "例）https://smashoku.com/product/1": "例如：https://smashoku.com/product/1",
  "ブランド": "品牌",
  "ブランド区分": "品牌分类　",
  "製造日・期限・保存": "制造日期/消费期限/保存方法",
  "入力方法": "输入方法",
  "包材の期限表示例": "包装材料的消费期限显示示例",
  "包材の期限表示": "包装材料的消费期限显示",
  "包材の製造日表示例": "包装材料的制造日期显示示例",
  "包材の製造日表示": "包装材料的制造日期显示",
  "賞味・消費期限区分": "赏味/消费期限区分",
  "製造日を含む": "包含制造日期",
  "例）1": "例）1",
  "例）10": "例）10",
  "日": "日期",
  "開封前の保存温度帯": "开封前的保存温度带",
  "開封後の保存温度帯": "开封后的保存温度带",
  "開封前": "开封前",
  "開封”前”": "开封前",
  "開封前の賞味・消費期限": "开封前的保质期和到期日",
  "開封前の期限設定の根拠": "开封前有效期的设定依据",
  "開封後の期限設定の根拠": "开封后有效期的依据",
  "開封前の賞味期限設定の根拠": "开封前有效期的设定依据",
  "開封後の賞味期限設定の根拠": "开封后有效期的依据",
  "賞味期限": "品尝期限",
  "消費期限": "消费期限",
  "品質保証期限": "品质保证期限",
  "製造年月日": "制造年月日",
  "期限設定無し": "没有期限设定",
  "例）理化学試験の結果・法令など": "例）根据理化学试验的结果/法令等",
  "出荷前の保存温度帯": "出货前的储存温度范围",
  "流通時の保存温度帯": "配送时的保存温度范围",
  "入荷時の保存温度帯": "到货后的储存温度范围",
  "開封後": "开封后",
  "開封”後”": "开封后",
  "荷姿規格": "包装标准",
  "規格入力方法": "规格输入方法",
  "商品単位": "商品单位",
  "選択または自由入力": "选择或者自由输入",
  "内容重量": "内容物重量",
  "内容重量(g)": "内容物重量（g）",
  "例）100": "例）100",
  "総重量(g)": "总重量（g）",
  "総重量": "总重量",
  "重量": "重量",
  "数量": "数量",
  "例）240g x 20袋 x 2ケース": "包装规格",
  "最低発注単位": "最小订购单位",
  "定貫": "固定数量",
  "不定貫": "非固定数量",
  "内容量（ml）を表記": "含量为毫升",
  "存在": "存在",
  "単位": "单位",
  "幅/長辺(mm)": "长/长边（mm）",
  "高さ(mm)": "高度（mm）",
  "奥/短辺(mm)": "宽/短边（mm）",
  "幅/長辺": "长/长边",
  "高さ": "高度",
  "奥/短辺": "宽/短边",
  "入数": "数量",
  "JANコード": "JAN代码",
  "例）12456789": "请进行选择",
  "ITF(GTIN-14)コード": "ITF（GTIN-14）代码",
  "ピース": "片",
  "パック": "盒",
  "ボール": "球",
  "ケース": "箱",
  "甲": "甲",
  "特徴・召し上がり方・注意点": "特征/食用方法/注意事项",
  "商品アピール文": "商品介绍文",
  "召し上がり方・使用方法": "食用方法/使用方法",
  "使用・保管・廃棄上の注意": "使用/保管/废弃时的注意事项",
  "関連する認証": "相关认证",
  "認証名": "认证名",
  "例）有機JAS": "例）有机JAS",
  "認証・認定機関": "认证/认定机关",
  "例）一般財団法人スマート食品": "例）一般财团法人SMART食品",
  "認証対象": "认证对象",
  "例）加工商品製造": "例）加工商品制造",
  "認証番号・記号": "认证号码/记号",
  "例）JAS123456789-1234-1": "例）JAS123456789-1234-1",
  "備考": "备注",
  "酒類情報": "酒类信息",
  "酒類区分": "酒类分类",
  "酒類分類": "酒精饮料分类",
  "酒類アルコール度数（%）": "酒精饮料酒精含量(%)",
  "例）5.2": "例5.2",
  "追加項目": "追加项目",
  "販売者（貴社情報）": "销售者（贵公司信息）",
  "組織プロフィールから引用": "可以从公司简介中引用",
  "組織名（全角カタカナ）": "公司名称（全角假名）",
  "WebページURL": "网页链接",
  "例)須磨都太郎": "例）须磨都太郎",
  "例）特になし": "例）没有",
  "製造者・加工者・輸入者": "制造商/加工商/进口商",
  "区分選択": "选择分类 ",
  "製造者の選択": "制造商的选择",
  "例）ISO22000 取得済": "例）ISO22000 已取得",
  "上記所在地以外での「製造・加工」": "制造所（工厂），加工所的设定",
  "製造所・加工所": "制造和加工厂",
  "製造所・加工所の選択": "制造和加工地点的选择",
  "製造所（工場）・加工所": "制造和加工厂",
  "製造所（工場）、加工所の設定": "制造和加工厂的设定",
  "スマート食品表示の商品から引用（引用する場合のみ）": "从带有智能食品标示的商品中引用（仅在引用时） ",
  "一覧から、スマート食品表示の商品を選択し、「下記に反映」をクリックしてください。": `请在列表中选择 "智能食品标示"的商品并点击 "应用"。`,
  "下記に反映": "应用",
  "一つ上に移動": "上升一个级别",
  "一つ下に移動": "往下移一个",
  "左に一段階上げる": "完成第一阶段的工作",
  "左に一段落上げる": "完成第一阶段的工作",
  "右に一段階下げる": "滴水不漏",
  "右に一段落上げる": "滴水不漏",
  "原材料名・添加物": "成分和添加剂",
  "例）ホワイトソース": "例）白酱",
  "配合率(％)": "配合率(％)",
  "配合率": "配合率",
  "例）20": "例）20",
  "添加物の用途": "添加剂的用途",
  "添加物用途": "添加剂的用途",
  "表示有無": "表示有无",
  "非表示の理由": "没有标记的理由",
  "未選択": "为选择",
  "一括表示上の表示名": "批量显示的表示名称",
  "原産地（最終加工地）": "来源地（最终加工点）",
  "製造者名": "制造商名称",
  "仕入元名": "供应商名称",
  "基原原料": "基础原料",
  "の基原原料": "基础原料",
  "基原原料の産地": "基础原料的来源",
  "アレルギー物質": "致敏原",
  "遺伝子組み換え農作物": "转基因作物",
  "遺伝子組換え対象農作物": "转基因作物",
  "の遺伝子組換え対象農作物": "的转基因作物（GMOs）",
  "対象農作物": "目标作物",
  "区分": "分类",
  "合計:": "共计:",
  "産地証明書": "原产地证明书",
  "ここに画像をドラッグ or クリックで選択": "通过拖动或单击此处选择图像",
  "ここにファイルをドラッグ or クリックで選択": "通过拖动或单击此处选择文件",
  "この商品を原材料として使用した場合の表示例（内訳は除く）": "将此商品用作原材料时的显示示例",
  "この商品を原材料として使用した場合の表示例": "将此商品用作原材料时的显示示例",
  "例）乳等を主要原料とする食品": "自由输入",
  "配合表全体に対する注意事項": "关于配方整体的注意事项",
  "名称区分・名称": "名称分类/名称",
  "名称区分": "名称分类 ",
  "名称": "名称",
  "品名": "品名",
  "品目": "项目",
  "種類別": "种类别",
  "種類別名称": "种类别名称",
  "例）サンドイッチ": "例）SMART",
  "原材料名": "原材料名",
  "例）トマト、レタス／保存料（ソルビン酸）": "例）番茄，生菜/防腐剂",
  "内容量・期限・保存方法": "内容量",
  "内容量区分": "内容量分类",
  "内容量": "内容量",
  "固形量": "固体含量",
  "固形量＋内容総量": "固体含量＋内容总量",
  "法令に基づき非表示": "法律规定的没有显示",
  "例）200g": "例）200g",
  "例）100g": "例）100g",
  "内容総量": "内容总量",
  "例）300g": "例）300g",
  "期限・保存方法": "期限/保存方法",
  "賞味・消費期限": "品尝/消费期限",
  "例）枠外右部に記載": "例）记载于框外右侧",
  "保存方法": "保存方法",
  "その他": "其他",
  "その他の項目を選択（複数選択可）": "其他选项（可多选）",
  "その他の項目": "其他选项",
  "原産国名": "原产国",
  "原料原産地": "原料原产国",
  "使用上の注意": "使用时的注意事项",
  "例）開缶後は、ガラスなどの容器に移し替えること": "如：开罐后，将其转移到玻璃或其他容器中",
  "調理方法": "料理方法",
  "例）加熱調理すること": "例）加热调理",
  "使用方法": "使用方法",
  "殺菌方法": "杀菌方法",
  "凍結前加熱の有無": "冷冻前有无加热",
  "例）加熱してありません": "例）不加热",
  "加熱調理の必要性": "加热调理的必要性",
  "例）加熱してください": "例）请加热",
  "でん粉含有率": "淀粉含有量",
  "例）3.5": "例）3.5",
  "eg35": "e.g. 3.5", // 例）3.5だとなぜかバグる
  "無脂乳固形分": "无脂乳固态含量比",
  "例）8.3": "例）8.3",
  "乳脂肪分": "乳脂肪含量比",
  "その他項目": "其他项目",
  "一括表示外の記載事項": "批量标签以外的声明",
  "例）開封後は、お早めにお召し上がりください。": "如：开封后，请尽快食用。",
  "販売者・製造者・加工者・輸入者": "卖家/制造商/加工厂/进口商",
  "表示パターンを選択": "选择显示模式",
  "製造者の所在地": "制造商地址",
  "例）都道府県市町村番地建物": "例）都道府県市町村番地建筑物名称",
  "例）都道府県市区町村番地建物": "例）都道府県市町村番地建筑物名称",
  "企業タブの情報を引用": "引用公司标签上的信息",
  "製造所名": "制造厂家名称",
  "製造所の所在地": "工厂地址",
  "製造者固有記号": "制造商特定符号",
  "製造者固有番号": "制造商特定编号",
  "製造者 + 製造所": "制造商 + 工厂",
  "製造者 + 製造所固有記号": "制造商 + 制造商特定符号",
  "販売者 + 製造所": "卖家 + 工厂",
  "販売者 + 加工所": "卖家 + 加工厂",
  "販売者 + 輸入者": "卖家 + 进口商",
  "販売者 + 製造所固有記号": "卖家 + 制造商特定符号",
  "販売者名": "销售商名称",
  "販売者の所在地": "销售商地址",
  "加工所名": "加工厂的名称",
  "加工所の所在地": "加工厂地址",
  "輸入者名": "进口商名称",
  "輸入者の所在地": "进口商所在地",
  "加工者名": "加工商名称",
  "加工者の所在地": "加工商地址",
  "加工者 + 加工所": "加工商+加工厂",
  "一括表示全体に対する備考": "关于所有批量显示的备注",
  "栄養成分（100g当たり）": "营养成分",
  "成分（100g当たり）": "营养成分",
  "入力内容": "输入内容",
  "栄養成分の備考": "营养成分的备份",
  "文科省公表の値を引用": "从文科省公布的数据中引用(未翻译的)",
  "食品名を入力して選択": "输入食品名称进行选择",
  "食品名を入力して検索": "输入食品名称进行选择",
  "ビタミン 13 種": "13种维生素",
  "例）1000": "例子",
  "熱量": "热量",
  "たんぱく質": "蛋白质",
  "脂質": "脂肪",
  "炭水化物": "碳水化合物",
  "食塩相当量": "食盐相当量",
  "水分": "水分",
  "灰分": "烟尘",
  "飽和脂肪酸": "饱和脂肪酸",
  "一価不飽和脂肪酸": "不饱和脂肪酸",
  "多価不飽和脂肪酸": "多价不饱和脂肪酸",
  "n-3 系脂肪酸": "n-3 系脂肪酸",
  "n-3系脂肪酸": "n-3系脂肪酸",
  "n-6 系脂肪酸": "n-6 系脂肪酸",
  "n-6系脂肪酸": "n-6系脂肪酸",
  "トランス脂肪酸": "反式脂肪酸",
  "コレステロール": "胆固醇",
  "糖質": "糖分",
  "糖類": "糖类",
  "食物繊維": "食物纤维",
  "水溶性食物繊維": "水溶性食物纤维",
  "不溶性食物繊維": "不溶性食物纤维",
  "ビタミンA": "维生素A",
  "レチノール": "视黄醇",
  "αカロテン": "α-胡萝卜素",
  "βカロテン": "β-胡萝卜素",
  "クリプトキサンチン": "隐黄质",
  "β-カロテン当量": "β-胡萝卜素当量",
  "ビタミンB1": "维生素B1",
  "ビタミンB2": "维生素B2",
  "ビタミンB6": "维生素B6",
  "ビタミンB12": "维生素B12",
  "ナイアシン": "烟酸",
  "葉酸": "叶酸",
  "パントテン酸": "泛酸",
  "ビオチン": "维生素H",
  "ビタミンC": "维生素C",
  "ビタミンD": "维生素D",
  "ビタミンK": "维生素K",
  "ビタミンE": "维生素E",
  "αトコフェロール": "α-生育酚",
  "βトコフェロール": "β-生育酚",
  "γトコフェロール": "γ-生育酚",
  "δトコフェロール": "δ-生育酚",
  "ミネラル 13 種": "13种矿物质",
  "ミネラル 12 種": "12种矿物质",
  "亜鉛": "锌",
  "カリウム": "钾",
  "カルシウム": "钙",
  "クロム": "铬",
  "セレン": "硒",
  "鉄": "铁",
  "銅": "铜",
  "マグネシウム": "镁",
  "マンガン": "锰",
  "モリブデン": "钼",
  "ヨウ素": "碘",
  "リン": "磷",
  "ナトリウム": "钠",
  "食品表示記載時の設定": "食品标示记载时的设定",
  "表示単位": "显示单位",
  "例）1袋": "例）1袋",
  "表示単位数量": "显示单位数量",
  "100": "100",
  "当たり": "每",
  "ml当たりの重量": "每毫升重量",
  "画像": "画像",
  "画像を選択（複数選択可）": "选择图像（可多选）",
  "ここに画像をドラッグ or クリック": "拖动或点击这里的图片",
  "包装材質": "包装材质",
  "包材の部位名": "包装材料的部位名称",
  "包材の材質記号・規格": "包装材料的材料符号和标准",
  "例）PE": "例如：PE",
  "重量（g）": "重量（克）",
  "重量(g)": "重量（克）",
  "例）3": "例3.",
  "封緘方法": "密封的方法",
  "例）ヒートシール": "例如：热封",
  "包材に表示する識別・認証マーク": "显示在包装材料上的标识/认证标志",
  "識別・認証マークの種類": "标识/认证标志的种类",
  "識別・認証マーク": "标识/认证标志",
  "認定機関": "认定机关",
  "その他の表示項目": "其他展示品",
  "遺伝子組替え表示（一括表示外）": "转基因标签（在批次标签之外）",
  "遺伝子組替え表示": "转基因标签",
  "例）大豆（遺伝子組み換えでない）": "例如，大豆（非转基因）",
  "原料原産地の表示（一括表示外）": "原材料的原产地说明（在批次标签之外）",
  "原料原産地の表示": "原材料的原产地说明",
  "例）国産": "如国内",
  "アレルギー物質の混入（コンタミネーション）注意表示": "过敏性物质污染（污染）的警告标签",
  "アレルギー物質の混入（コンタミネーション）注意": "过敏性物质污染（污染）的警告标签",
  "アレルギー物質の混入（コンタミネーション）注意喚起": "过敏性物质污染（污染）的警告标签",
  "アレルギー物質の混入（コンタミネーションの注意表示）": "过敏性物质污染（污染）的警告标签",
  "例）本品製造工場では、卵・乳成分を含む製品を製造しております。": "如：含有鸡蛋和乳制品成分的产品是在生产该产品的工厂生产的。",
  "米（こめ）トレーサビリティ法対象区分・産地情報伝達方法": "大米溯源法案的对象区分/产地信息传达方法",
  "米トレーサビリティ法対象区分・産地情報伝達方法": "大米溯源法案的对象区分/产地信息传达方法",
  "対象区分": "是否属于对象",
  "対象": "对象",
  "対象外": "非对象",
  "産地名の表示場所": "产地名的标示位置",
  "包材全体に対する備考": "关于包装材料全体的备注",
  "製造の基本情報": "制造的基本信息",
  "製造工程": "制造工程",
  "製造工程の添付ファイル": "制造工程的添加文件",
  "画像・PDFを添付": "添加图像/PDF",
  "テキスト": "文本",
  "例）原材肉の選別 → 原料肉の整理 → 味付け → 熟成 → 乾燥 → 冷却 → スライス → 計量 → 包装 → 金属探知機 → 製品検査 → 出荷": "例）生肉分选 → 生肉整理 → 调味 → 陈化 → 干燥 → 冷却 → 切片 → 称重 → 包装 → 金属检测机 → 产品检验 → 运输",
  "工程名": "过程名称",
  "例）原料受け入れ": "如：原材料验收",
  "管理項目（対象など）": "控制项目（如目标）",
  "管理項目": "控制项目",
  "管理ポイント": "管理要点",
  "例）自由入力": "例如：自由输入",
  "管理基準（CLなど）": "管理标准（如CL）",
  "モニタリング方法": "监测方法",
  "危害要因": "危害的原因",
  "製造法基準": "制造法标准",
  "誰が": "谁",
  "だれが": "谁",
  "どのように": "如何",
  "使用器具・設備": "使用的仪器和设备",
  "頻度": "频率",
  "基準外措置内容": "非标准措施的细节",
  "基準判断者": "标准法官",
  "基準外判断者": "非标准法官",
  "危害": "伤害",
  "検証方法": "验证方法",
  "何を": "什么",
  "改善措置": "改善措施",
  "方法": "方法",
  "記録文章名": "记录文本名称",
  "記録文書名": "记录文本名称",
  "異物検出方法": "异物检测方法",
  "X線の対象材質": "X射线的目标材料",
  "球(φmm)": "球（直径毫米）",
  "例）2.5": "例2.5",
  "線": "射线",
  "直径(φmm)": "直径 (φmm)",
  "長さ(mm)": "长度（mm）",
  "線 直径(φmm)": "射线直径 (φmm)",
  "線 長さ(mm)": "射线长度（mm）",
  "金属探知機の使用": "有无使用金属探测器",
  "金属探知機の使用有無": "有无使用金属探测器",
  "金属探知機": "金属探测器",
  "Fe(φmm)": "Fe(mm)",
  "例）1.0": "例）1.0",
  "Sus(φmm)": "Sus (mm)",
  "例）3.0": "例3.0",
  "Non-Fe(φmm)": "非铁（直径毫米）",
  "例）2.0": "例2.0",
  "金属探知機の動作確認頻度等の備考": "有关确认金属探测器的动作频率的备注",
  "ウェイトチェッカーの使用": "是否使用重量检查器",
  "ウェイトチェッカーの使用有無": "是否使用重量检查器",
  "ウェイトチェッカー": "重量检查器",
  "下限": "下限（g）",
  "上限": "上限（g）",
  "その他の異物検出方法": "其他检出异物的方法",
  "包装前後の最終殺菌方法": "包装前后的最终杀菌方法",
  "最終殺菌方法": "最终杀菌方法",
  "最終殺菌後の冷却方法": "最终杀菌后的冷却方法",
  "衛生監視票": "卫生监视票",
  "製造品質添付資料": "制造质量附录",
  "製造品質添付資料1": "制造质量附录1",
  "製造品質添付資料2": "制造质量附录2",
  "衛生監視表など製造品質関連の添付資料（複数選択可）": "制造质量相关的附录（可多选）",
  "コンタミネーション": "污染源",
  "同一工場内に存在するアレルギー物質": "存在于同一工厂内的过敏原物质",
  "製造ラインに混入する恐れのあるアレルギー物質": "有可能混入生产线的过敏原物质",
  "コンタミネーション防止策": "预防污染措施",
  "規格・検査": "规格和检测",
  "物理規格": "物理规格",
  "項目": "项目",
  "測定方法": "测量方法",
  "検査頻度": "检查频率",
  "管理基準": "管理标准",
  "計測値": "测量值",
  "検査機関": "检查机关",
  "化学規格": "化学规格",
  "微生物規格": "微生物规格",
  "品質保持方法": "防腐的方法",
  "品質保持剤の種類": "防腐剂的种类",
  "品質保持剤の名称": "Name of Quality Retention Materials",
  "製造全体に対する備考": "对整体生产的评论",
  "商品名・回収・提出先名などで検索": "按商品名称、收藏品名称或提交地址等搜索",
  "対応すべき提出依頼": "待处理的提交申请",
  "事業者名": "企业名称",
  "共有数": "共享数",
  "確認待": "待确认",
  "要提出": "需要提交",
  "中断": "中断",
  "相手方取下げ": "对方撤诉",
  "相手方中断": "被对方打断",
  "取下げ": "撤回",
  "提出済": "已完成提交",
  "要修正": "需要纠正",
  "先方確認中": "等待对方的确认",
  "未受領": "未收到",
  "差戻し": "还押",
  "要確認": "需要确认",
  "回収済": "已回收",
  "通知あり": "有通知",
  "提出一覧": "提交清单",
  "商品名・回収・回収先名などで検索": "按商品名称、系列或系列目的地名称等进行搜索",
  "この依頼を取下げる": "撤回这一请求",
  "未完了の項目の左にあるチェックボックスを1つ選択してください": "请在未完成的项目左侧选择一个复选框",
  "提出完了": "提交完成",
  "自社商品名": "内部商品名称",
  "依頼商品名": "要求的商品名称",
  "依頼元": "要求者",
  "依頼日": "申请日期",
  "依頼文・コメント履歴": "请求信和评论历史",
  "提出期限": "截止日期",
  "初回共有": "第一次分享",
  "最終更新": "最后更新的",
  "履歴を表示": "显示历史",
  "残り": "剩余的",
  "下記の内容で、規格書の提出依頼が来ています。": "已收到提交规格书的请求，其中包括以下信息。",
  "依頼商品": "要求的货物",
  "締め切り": "截止日期",
  "コメント": "评论",
  "新しく作成": "新创建的",
  "閲覧メンバーはこの機能は使用できません": "查看成员无法使用此功能",
  "配信済み規格書から選択": "从已交付的规格书中选择",
  "配信済の自社規格書を選択して提出": "选择并提交你自己已经交付的规格书",
  "下記を入力して提出してください。": "请输入并提交以下内容。",
  "配信済み自社規格書の選択": "选择交付的内部规格书",
  "商品名、自社管理コードで絞り込み": "通过商品名称和公司控制代码缩小范围",
  "非公開にする項目": "要保密的项目",
  "この依頼者への提供税抜単価(円)": "提供给该客户的不含税单价（日元）",
  "以上で提出": "与上述内容一起提交",
  "設定を変更したい場合、画面右上「アカウント設定」 > 「回収・提出先」から該当の企業を選択 > 「この回収・提出先に対する規格書の非公開項目」で設定してください。":
    "如果你想改变设置，请从屏幕右上角的 '账户设置'>'收集/提交收件人'>'该收集/提交收件人规格书中的私人项目 '下选择相关公司。",
  "規格書の修正依頼": "要求修改规格",
  "下記の内容で、規格書の修正依頼が来ています。": "已收到要求修改规格书的请求，其中包括以下信息。",
  "再提出する規格書を選択": "选择要重新提交的规格书",
  "選択した規格書で再提出": "用选定的规格书重新提交",
  "選択した規格書を修正して再提出": "修订并重新提交选定的规格书",
  "部署": "部门",
  'コチラ': '这里',
  "手入力で追加": "手动追加",
  "手入力で編集": "手动编辑",
  "すべての回収規格書の一覧": "所有回收规格的清单",
  "この回収依頼を中断する": "中断此回收请求",
  "規格書の編集": "规格书的编辑",
  "有り": "有",
  "無し": "无",
  "生鮮食品": "生鲜食品",
  "加工食品": "加工食品",
  "添加物": "添加剂",
  "添加物製剤": "添加剂制剂",
  "製剤": "制剂",
  "簡易入力": "简易输入",
  "基本入力": "基本输入",
  "詳細入力": "详细输入",
  "そうけんくん標準規格書（v2.3）取り込み": "导入Soken-kun标准规格（v2.3）",
  "MerQurius原料品質規格書（v3.1）取り込み": "导入MerQurius标准规格（v3.1）",
  "eBASE標準出力データ（フォルダ選択）取り込み": "导入eBASE标准输出数据",
  "標準フォーマット（PDF） 出力": "导出标准格式（PDF）",
  "そうけんくん標準規格書（v2.3）出力": "导出Soken-kun标准规格（v2.3）",
  "MerQurius原料品質規格書（v3.1）出力": "导出MerQurius标准规格（v3.1）",
  "eBASE標準出力データ（ZIP）出力": "导出eBASE标准输出数据",
  "eBASEフォーマット（zip）": "eBASE标准格式（zip）",
  "そうけんくん（v2.3）フォーマット（Excel）": "Soken-kun（v2.3）标准格式（Excel）",
  "MerQurius原料品質規格書（v3.1）フォーマット（Excel）": "MerQurius标准规格（v3.1）标准格式（Excel）",
  "インフォマート規格書データ取り込み（ZIP）": "Informart格式（ZIP）",
  "BtoBプラットフォーム規格書（プレミアム全項目）の取り込み: ": "导入BtoB平台规格书（全项目）：",
  "株式会社インフォマートが提供する「BtoBプラットフォーム規格書」の出力形式「規格書（プレミアム全項目）」を取り込み、規格書を作成することができます。":
    "根据株式会社Informart提供的“BtoB平台规格书”输出格式“规格书（全项目）”，可以创建规格书。",
  "印刷のための条件を設定してください。印刷準備には数十秒かかることがございます。なお、印刷して提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。": "请设置打印条件。打印准备需要数百秒。",
  "正確なデータ変換のための確認事項": "为了确保数据转换的正确性，请确认以下事项",
  "この規格書データを「x」が読み取るために、当システム側で自動変換する内容、もしくは不足している内容があります。確認事項は、修正画面でも確認することができます。" :
    "为使'{0}'正确导入数据，有些内容缺失或将被自动转换。你可以在校正屏幕上检查确认项目。",
  "印刷": "打印",
  "別の規格書と比較": "比较其他规格",
  "【自社】": "【自社】",
  "【回収】": "【回收】",
  "依頼名": "请求名称",
  "内容を確認し、承認": "确认内容并接受",
  "承認しました": "已接受",
  "承認を取り消しました": "已取消接受",
  "承認を取り消す": "取消接受",
  "すべての承認が完了しています": "所有接受已完成",
  "仕入れ価格": "购买价格",
  "円": "日元",
  "円（税抜）": "日元（不含税）",
  "酒類アルコール分": "酒类酒分",
  "例": "例",
  "例）": "例）",
  "開封前の": "开封前的",
  "開封後の": "开封后的",
  "製造所固有記号": "工厂特定符号",
  "参考資料": "参考资料",
  "参考情報": "参考信息",
  "包材画像": "包装图片",
  "包材材質": "包装材质",
  "提出情報": "提交信息",
  "提出元組織": "提交组织",
  "組織": "组织",
  "手動追加した規格書の管理情報（自分の組織以外には表示されません）": "管理自己的规格书（不会显示别的组织）",
  "自社の担当部署": "自社的负责部门",
  "実際に回収した規格書（紙のスキャンデータなど）": "实际回收的规格书（纸的扫描数据等）",
  "この更新分について": "关于此更新的内容",
  "この規格書全体について": "关于此规格书的全部内容",
  "作成": "创建",
  "この規格書の管理用のタグを設定すると、検索や整理などが効率よくできます。": "设置此规格书的管理标签可以使搜索和整理更加高效。",
  "この規格書について、管理上のコメントがある場合、入力してください。": "如果此规格书有管理上的评论，请输入。",
  "更新分": "更新分",
  "配信分": "交付分",
  "ここにキーワードを入力して検索": "在此输入关键字搜索",
  "この処理は数十秒かかる場合があります": "这个过程可能需要几十秒",
  "内容をチェックしています": "正在检查内容",
  "出力しています": "正在输出",
  "該当する": "符合",
  "該当しない": "不符合",
  "内容量(ml)を併記": "显示内容量（ml）",
  "表示": "显示",
  "非表示": "隐藏",
  "事業所名": "公司名称",
  "事業所": "公司",
  "全角カタカナ": "全角片假名",
  "加入": "加入",
  "非加入": "非加入",
  "包材に産地名を表示": "在包装材料上标示产地名",
  "全体に反映": "全体反映",
  "未設定": "未设定",
  "としてログイン中": "登录中",
  "タブ": "标签",
  "全データを閲覧可能、編集可能": "所有数据都可以被查看和编辑",
  "全データを閲覧可能、所属外データの編集不可": "所有数据都可以被查看，但非会员不能编辑",
  "所属外データを非表示、編集不可、引用可能": "你的部门以外的数据可以被隐藏，无法编辑和引用",
  "所属外データを閲覧不可、編集不可、引用不可": "你所在部门以外的数据不能被查看、编辑或引用",
  "管理メンバー": "管理成员",
  "編集メンバー": "编辑成员",
  "閲覧メンバー": "查看成员",
  "%以上": "%以上",
  "%未満": "%未満",
  "例）開缶後はガラス等の容器に移し換えること": "例：开罐后转移到玻璃等容器中",
  "確認事項": "确认事项",
  "修正": "修正",
  "内容": "内容",
  "無視して出力": "忽略并输出",
  "規格書を修正する": "修正规格书",
  "規格書を修正": "修正规格书",
  "は自動的に付与されます": "自动显示",
  "出荷前": "出货前",
  "流通時": "流通时",
  "入荷時": "入货时",
  "例）0123456789012": "例：0123456789012",
  "例）01234567890123": "例：01234567890123",
  "の保存温度帯": "的保存温度带",
  "任意の文字を入力することで、項目を追加することも可能です": "你也可以添加项目",
  "製造工程が分かるファイルを添付してください。": "Please attach a file that shows the manufacturing process.",
  "例）ISO 22000 取得済": "例：ISO 22000 已获得",
  "この規格書と連携している食品表示の商品（または内訳の原材料）が更新されました。": `与此规格相关的 "智能食品标示" 中的商品已被更新。`,
  "詳細画面の「管理情報」から承認条件を満たしてから「配信」してください。": `请在 "管理信息" 页面批准该版本，以便交付。`,
  "担当部署": "负责部门",
  "回収規格書": "回收规格",
  "比較元": "比较源",
  "比較先": "比较目标",
  "提出先の組織名": "提交目标组织名",
  "はい": "是",
  "いいえ": "否",
  "配信済": "已交付",
  "この規格書は提出済みです": "该规格已提交",
  "この規格書は受領済みです": "该规格已收到",
  "商品単位あたりの重量を入力してください": "请输入每个商品单位的重量",
  "風袋込の商品単位あたり重量を入力してください": "请输入每个商品单位的重量（包括皮重）",
  "添加物名を入力して検索": "按添加剂名称搜索",
  "規格書を提出する": "提交规格",
  "８文字以上の英数字": "英文或数字，最多8个字符",
  "英数字記号": "字母数字符号",
  "この設定は現在変更できません": "该设置不能修改",
  "この画面では、回収規格書の作成時に選択する提出元のデータを編集・削除することができます。": "该页面可以编辑和删除提交源数据",
  "この画面では、規格書の作成時などに入力を補助する企業のデータを編集・削除することができます。※ここから編集・削除しても過去に配信した規格書の情報は書き換えられません。":
    "您可以在此页面中编辑和删除公司的数据。交付规格后，你不能修改或删除数据。",
  "このデータを削除": "删除该数据",
  "メンバーの追加": "添加成员",
  "メンバーを編集": "编辑成员",
  "所属部署": "所属部门",
  "管理メンバーはこの項目は必須です": "这是对管理员成员的要求",
  "メンバーを更新しました": "成员已更新",
  "メンバーを削除しました": "成员已删除",
  "保存しました": "已保存",
  "関連事業者の削除": "删除相关企业",
  "承諾して削除": "批准删除",
  "削除しました": "已删除",
  "このデータを削除します。<br><br>このデータを削除しても、過去にこの関連事業者を使って配信された規格書の内容は、変更されません。":
    "您将删除该数据。<br><br>删除该数据后，如果您以前使用过该企业，则该企业的规格更新不会被更新。",
  "この画面は、管理メンバーのみに表示されています。こちらでは、現在ご契約のプランの確認や、過去の利用詳細を確認することができます。":
    "该页面只对管理员成员可见。您可以在此页面中查看当前契约的设置和历史记录。",
  "回収・提出先（提出元企業）": "回收和提交目标",
  "次回請求日": "下次请求日",
  "期限切れ": "已过期",
  "金額": "金额",
  "カード番号": "卡号",
  "利用料金は各契約期間の初日に請求され、お客様のアカウントに反映されるまで数日かかることもございます。": "每个契约的开始日，将会收取费用，直到您的账户反映到期日为止。",
  "食品表示プランの解約": "解约食品标签契约",
  "規格書プランの解約": "解约规格書契约",
  "食品表示プランの再契約": "再契约食品标签契约",
  "規格書プランの再契約": "再契约规格書契约",
  "再契約": "再契约",
  "プランを再開しました": "契约已重新开启",
  "プランの再開に失敗しました": "契约重新开启失败",
  "了承して解約": "批准解约",
  "領収書番号": "收据号",
  "停止予定": "停止预定",
  "停止日": "停止日",
  "ログアウトしました": "已退出",
  "円/月": "日元/月",
  "この規格書を配信します。よろしいですか？": "您将交付该规格書。确定吗？",
  "配信しました": "已交付",
  "未保存の変更があります。このまま進むと内容は保存されませんが、よろしいですか？": "您还有未保存的更改。如果继续，更改将不会被保存。确定吗？",
  "本当にキャンセルしますか": "确定取消吗",
  "作成のキャンセル": "取消创建",
  "変更のキャンセル": "取消修改",
  "作成中の内容は保存されません。": "创建中的内容将不会被保存。",
  "前回から変更した内容は保存されません。": "从上次修改后的内容将不会被保存。",
  "トークン作成に失敗しました": "创建令牌失败",
  "クレジットカードの登録に失敗しました": "登记信用卡失败",
  "カード情報を更新しました": "信用卡已更新",
  "画面をリロードします": "将重新加载页面",
  "プランの解約に失敗しました": "解约契约失败",
  "プランを解約しました": "已解约契约",
  "無料で 14 日間、全機能をお使いいただくことができます。": "您可以免费使用14天的全部功能。",
  "以上で送信": "以上发送",
  "お問い合わせ内容": "联系内容",
  "資料のご請求・大量の原材料や商品を扱い企業様の相談・その他ご質問がございましたら、以下よりお気軽にお問い合わせください":
    "如果您有关于请求资料、大量原材料或商品的企业的咨询或其他问题，请随时联系我们",
  "こちらにお問い合わせ内容を記入ください。": "请在此处输入您的联系内容。",
  "招待取り消し": "取消邀请",
  "招待を取り消しました": "已取消邀请",
  "一括削除": "批量删除",
  "選択されたn項目を一括で削除しますか？(この操作はもとに戻せません)": "确定批量删除所选的{0}项？（此操作无法撤销）",
  "事業者がみつかりませんでした": "找不到企业",
  "有効な事業者が選択されていません" : "未选择有效企业",
  "規格書の提出依頼を送信しました": "已发送规格書提交请求",
  "この回収依頼を中断しますか？相手方にも、提出の必要性がないことを明示します。<br><br>同じ内容で回収したい場合には、再度回収依頼を出してください。":
    "确定中断此回收请求？将向对方明确说明不需要提交。<br><br>如果需要再次回收，请重新发送回收请求。",
  "回収依頼を中断しました": "已中断回收请求",
  "確認が必要な項目があります": "有需要确认的项目",
  "回収規格書一覧.csv": "回收规格书列表.csv",
  "xのデータ取り込みが完了しました": "{0}的数据导入已完成",
  "不足": "不足",
  "この提出依頼を取り下げる": "取消此提交请求",
  "この提出依頼を取下げますか":
    "你想撤回这个提交请求吗？发件人公司将被告知，该提交请求已被撤回。.<br><br>要再次提交这一请求，他们需要再次向我们发送请求",
  "取り下げ理由（相手方に送信）": "撤回原因（发送给对方）",
  "以上で取下げ": "撤回",
  "依頼を取り下げました": "已取消请求",
  "受領しました": "已收到",
  "更新しました": "已更新",
  "承認が必要です": "需要批准",
  "回収規格書（x）を手動作成しました。": "已手动创建回收规格书（{0}）。",
  "回収規格書（x）を手動更新しました。": "已手动更新回收规格书（{0}）。",
  "xを下書き保存しました。": "已保存草稿{0}。",
  "xを正規の版として保存しました。": "已保存正式版{0}。",
  "提出可能なステータスではありません": "不是可提交的状态",
  "規格書を提出しました": "已提交规格书",
  "xを提出しました": "已提交{0}",
  "ユーザーが作成されました": "已创建用户",
  "ログインが必要です": "需要登录",
  "ログインしました": "已登录",
  "メールアドレスの変更が完了しました": "已完成更改电子邮件地址",
  "メールアドレスの変更に失敗しました。お手数をおかけ致しますが、最初からやり直してください": "更改电子邮件地址失败。请重新尝试",
  "確認メールを送信しました。メール内のリンクをクリック後に変更が適用されます": "已发送确认电子邮件。更改将在点击电子邮件内的链接后生效",
  "パスワードを更新しました": "已更新密码",
  "プロフィールを更新しました": "已更新个人资料",
  "プロフィールを更新しました。画面更新のためリロードします": "已更新个人资料。将重新加载页面",
  "規格書設定を更新しました。画面をリロードします": "已更新规格书设置。将重新加载页面",
  "ここでは、皆さまから寄せられるご質問の中から特に多かったものを共有しています。": "这里是我们收到的一些常见问题的解答。",
  "この画面では、回収・提出先との状態や自社用のメモを記述することができます。": "在这个页面上，您可以记录与回收方或提交方的状态以及自己的备注。",
  "管理情報（自分の組織以外には表示されません）": "管理信息（不会显示在其他组织中）",
  "先方担当者名": "对方负责人姓名",
  "担当者名": "负责人姓名",
  "回収・提出先の主要区分": "回收/提交方的主要区分",
  "創業年": "创业年份",
  "年商（推定含む）": "年销售额（含推测）",
  "従業員数": "员工人数",
  "この回収・提出先に対する規格書の非公開項目": "对于此回收/提交方的规格书的非公开项目",
  "過去に提出した企業にも配信されます": "将发送给过去提交过的企业",
  "詳細画面の「管理情報」から承認条件を満たしてから「提出」してください。": "请在详细页面的“管理信息”中，满足批准条件后再“提交”。",
  "アプリケーションが更新されました": "应用程序已更新",
  "画面の再読込を行ってください<br>（ここをクリックして再読込する）": "请重新加载页面<br>（点击此处重新加载）",
  "主な用途": "主要用途",
  "その他の用途": "其他用途",
  "該当する項目がありません。": "没有符合条件的项目。",
  "特定遺伝子組換え作物": "特定的转基因作物",
  "部署設定の有効化": "启用部门设置",
  "部署に属していないメンバーは、自動的に「管理」所属に設定されます": "不属于任何部门的成员将自动设置为“管理”部门",
  "確認して閉じる": "确认并关闭",
  "1Aaあア": "xxx",
  "本当にキャンセルしますか？": "确定要取消吗？",
  "通知": "通知",
  "回収規格書の定期確認": "定期检查回收规格书",
  "通知のタイミング": "通知时间",
  "未確認時の再通知": "再次通知未确认的",
  "再通知しない": "不再通知",
  "この設定を変更すると、新しい条件に一致する通知が画面上に作成されます（メールは送信されません）": "更改此设置后，将在屏幕上创建与新条件匹配的通知（不会发送电子邮件）",
  "「回収規格書の定期確認」の変更": "更改“定期检查回收规格书”",
  "xにこの規格書の新しい版が配信されました。影響範囲を確認して、問題がなければ、通知を削除してください。":
    "该规格书的新版本已于{0}交付。 检查影响的范围，如果没有问题，就删除通知。",
  "この規格書は、「最終更新日」より t日以上 が経過しています。規格書の最新状態を確認し、問題がなければ、この通知を削除してください。":
    "这个规格书已经 '最后更新' 了{0}天以上。 请检查该规格书的最新状态，如果没有问题，请删除本通知。",
  "最新状態を確認したため、この通知を削除": "已确认最新状态，删除本通知",
  "確定": "确定",
  "通知を削除": "删除本通知",
  "表示 / 非表示 の切替え": "切换显示/隐藏",
  "表示リスト": "显示列表",
  "非表示リスト": "隐藏列表",
  'この画面は、組織内の「管理者」のみが操作可能です。以下の設定はあなただけではなく、組織全体に影響されるため、変更の際はくれぐれも注意してください。':
    "此页面仅由组织内的“管理员”操作。以下设置不仅影响您自己，还会影响整个组织，因此在更改时请注意。",
  "回収依頼": "要求收集",
  "入力必須事項の初期項目": "初始必填项",
  "ホームへ": "回到首页",
  "承認条件を満たしてから「受領」してください。": "请在满足批准条件后“接收”",
  "登録後に変更することが可能です": "注册后可以更改",
  "重量単位": "重量单位",
  "同一工場内": "同一工厂内",
  "同一製造ライン": "同一制造线",
  "X線検査機の使用有無": "是否使用X射线检测机",
  "別サービス「スマート食品表示」を使えば、「規格書（原材料）」を選択して割合を入力するだけで、自動的に原材料名・栄養成分を計算・作成することができます。詳しくは右の動画をご覧ください。":
    "如果使用“智能食品标示”服务，只需选择“规格书（原料）”并输入比例，就可以自动计算和创建原料名称和营养成分。有关详细信息，请参阅右侧的视频。",
  "スマート食品表示を開く": "打开智能食品标示",
  "最大横幅で表示": "以最大宽度显示",
  "配合率を「全体配合率」で入力": "以“总体配合率”输入配合率",
  '確認': '确认',
}

// Locale
import Vue from 'vue';
import VueI18n, {Locale, Path} from 'vue-i18n';
import Qs from 'qs';
import {createExtrasFromVm} from "@/bootstraps/error-handler";
import * as Sentry from "@sentry/browser";
import {Severity} from "@sentry/types/dist/severity";
import appType, {AppTypes} from "@/app-types";

Vue.use(VueI18n);

export const LangDict = {
  ja: "日本語",
  en: "English（US）",
  cn: "中文（简体）",
} as const;
export type LangType = keyof typeof LangDict;

// https://senmyou.xyz/vue-i18n-%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E3%81%BF%E3%81%BE%E3%81%97%E3%81%9F/
function parseLocale(locale:string) {
  switch (locale) {
    case 'en':
    case 'en-US':
      return 'en';
    case "zh":
    case "cn":
    case 'zh-CN':
    case 'zh-SG':
    case 'zh-Hans':
    case 'zh-Hans-CN':
    case 'zh-Hans-SG':
      return 'zh-CN'
    case 'tw':
    case 'zh-HK':
    case 'zh-MO':
    case 'zh-TW':
    case 'zh-Hant':
    case 'zh-Hant-CN':
    case 'zh-Hant-TW':
    case 'zh-Hant-HK':
    case 'zh-Hant-MO':
      return 'zh-TW'
    default:
      return 'ja';
  }
}

function parseLang(language:string) {
  // e.g. ['ja', 'en-US', 'en', 'pt']
  const lang = language.toLowerCase().split("-");
  switch (lang[0]) {
    case 'zh':
    case "zhcn":
    case "zhtw":
    case "cn":
      return 'cn';
    case 'ja':
      return 'ja';
    default:
      return 'en';
  }
}

function initI18n() {
  return new VueI18n({
    locale: 'ja',
    messages: {
      ja: require(`../lang/ja.js`).default,
    },
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    missing: (locale: Locale, key: Path, vm: Vue | null, values: any) => {
      if (locale === 'ja') return;
      if (appType !== AppTypes.Spec) return;

      const msg = `missing translation. key: ${key}, locale: ${locale}`
      console.warn(msg);

      if (vm) {
        const extras = createExtrasFromVm(vm);
        if (process.env.NODE_ENV === 'development') {
          console.warn(extras);
        }
        Sentry.captureException(msg, { extra: extras, level: Severity.Warning });
      } else {
        Sentry.captureException(msg, { level: Severity.Warning });
      }
    }
  });
}

export function judgeBrowserLang(): LangType {
  if(process.env.NODE_ENV === 'testing') {
    return 'ja';
  }
  const lang = Qs.parse(location.search.substring(1)).lang as string|undefined;
  return parseLang(lang || window.navigator.language);
}

export const i18n = initI18n();

export function setLang(lang: LangType) {
  i18n.setLocaleMessage(lang, require(`../lang/${lang}.js`).default);
  i18n.locale = lang;
}
export function judgeLang(): LangType {
  return i18n.locale as LangType;
}

type LangText = {
  ja: string;
  en?: string;
  cn?: string;
}
export function getTextByLang(prop: LangText, lang = i18n.locale): string {
  return (lang in prop) ? prop[lang]! : prop.ja;
}


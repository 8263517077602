<template>
  <div class="el-input" :class="{'el-input--small': size === 'small'}" :style="{width: width}">
    <input @compositionend="onCompositionEnd"
           @keydown.down.stop.prevent="arrowDown"
           @keydown.up.stop.prevent="arrowUp"
           @blur="blur"
           @focus="handleFocus"
           @change="handleChange"
           :value="formattedValue"
           type="text" autocomplete="off" class="el-input__inner input-number"
           ref="input"
           :disabled="disabled"
           maxlength="22"
           title=""
           :min="min" :max="max" :placeholder="placeholder"
    />
  </div>
</template>

<script>
  import StringUtils from "@/utils/string-utils";
  import isNumber from "lodash/isNumber";
  import {InputMixin} from "@/components/Form/mixins/input-mixin";

  export default {
    mixins: [InputMixin],

    props: {
      value: {required: true },
      size: { type: String, required: false},
      min: { type: Number, default: 0 },
      max: { type: Number, required: false },
      width: { type: String, required: false },
      placeholder: { required: false, type: String},
      disabled: { default: false, type: Boolean},
    },
    data() {
      return {
      };
    },
    created() {
      const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str));
      if (isNumeric(this.value) && !isNumber(this.value)) {
        this.$emit('input', Number(this.value));
      }
    },
    computed: {
      formattedValue() {
        if (this.value === null || this.value === undefined) return null;
        if (isNumber(this.value)) {
            return this.value.toLocaleString(undefined, { maximumFractionDigits: 9 })
        }
        return this.value;
      }
    },
    methods: {
      filterAndEmit(val) {
        if (val === null || val === undefined) {
          this.$emit('input', null);
          return;
        }
        if (isNumber(val)) {
          this.$emit('input', val);
          return;
        }

        const filtered = val.replaceAll(',', '').match(/-?\d+(\.\d+)?/g);
        this.$emit('input', filtered ? Number(filtered.join('')) : null);
      },
      onCompositionEnd(evt) {
        const val = StringUtils.toHankaku(evt.target.value);
        this.filterAndEmit(val);
      },
      blur() {
        this.filterAndEmit(this.$refs.input.value);
        this.$refs.input.value = this.formattedValue;

        this.handleBlur();
      },
      arrowUp() {
        if (!this.value) {
          return this.$emit('input', 1);
        }
        if (this.max === undefined || this.value < this.max) {
          this.$emit('input', this.value + 1);
        }
      },
      arrowDown() {
        if (!this.value) {
          return this.$emit('input', 0);
        }
        if (this.value > this.min) {
          this.$emit('input', this.value - 1);
        }
      },
    }

  }
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
input::-webkit-input-placeholder {
  text-align: left;
}
.input-number {
  text-align: right;
}
</style>

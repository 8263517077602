var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"el-form-item",class:[{
    // 'el-form-item--feedback': elForm && elForm.statusIcon,
    'is-error': _vm.validateState === 'error',
    // 'is-validating': validateState === 'validating',
    // 'is-success': validateState === 'success',
    'is-required': _vm.isRequired || _vm.required,
    // 'is-no-asterisk': elForm && elForm.hideRequiredAsterisk
    'hide-error': _vm.hideError,
  } ],on:{"mouseenter":function($event){return _vm.$emit('hover', $event, true)},"mouseleave":function($event){return _vm.$emit('hover', $event, false)}}},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"el-form-item__label",attrs:{"for":_vm.labelFor}},[_vm._t("label",[_vm._v(_vm._s(_vm.label + _vm.form.labelSuffix))])],2):_vm._e(),_c('div',{staticClass:"el-form-item__content"},[_vm._t("default"),_c('div',{staticClass:"el-form-item__error js-el-form-item__error",class:{'el-form-item__error--inline': _vm.inlineMessage }},[(_vm.errorAsHtml)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.validateMessage)}})]:[_vm._v(_vm._s(_vm.validateMessage))]],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
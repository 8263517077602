import { render, staticRenderFns } from "./HeaderComponentWrapper.vue?vue&type=template&id=5c3e7f6b&scoped=true&"
import script from "./HeaderComponentWrapper.vue?vue&type=script&lang=ts&"
export * from "./HeaderComponentWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderComponentWrapper.vue?vue&type=style&index=0&id=5c3e7f6b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3e7f6b",
  null
  
)

export default component.exports
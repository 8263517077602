const KEY = 'smashoku.auth.redirectUrl';

export function putRedirectTo(redirectUrl: string | null) {
  if (redirectUrl) {
    localStorage.setItem(KEY, redirectUrl as string);
  }
}

export function pullRedirectTo(): string | null {
  const res = localStorage.getItem(KEY);
  localStorage.removeItem(KEY);
  return res;
}

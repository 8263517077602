import smartlookClient from 'smartlook-client'
import {AuthUser} from "@/libs/auth";
import {clarity} from "clarity-js";
import * as Core from "clarity-js/types/core";

export function initSmartlook (user: AuthUser) {
  if (process.env.NODE_ENV === 'production') {
    smartlookClient.init('882c36526e75532d4b1ccc51a3afe9ae39c85883');
    smartlookClient.record({forms: true, ips: true, numbers: true, emails: true, api: true,});

    smartlookClient.identify(user.id, {
      name: user.name,
      email: user.email,
      companyId: user.company.id,
    })
  }
}

export function initClarity(user: AuthUser) {
  if (process.env.NODE_ENV !== 'production') return;

  clarity.identify(user.id.toString());
  clarity.set("userId", user.id.toString());
  clarity.set("email", user.email.toString());
  clarity.set("companyId", user.company.id.toString());
  const config = {
    projectId: 'iwjro3haa2',
  } as Core.Config;
  clarity.start(config);
  console.log('clarity started');
}

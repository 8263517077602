import RepositoryBase from "@/repositories/repository-base";
import UserEntity from "@/entities/user-entity";

export default class UserRepository extends RepositoryBase<UserEntity> {
  protected endpoint = "users";
  protected ctor:new(data) => UserEntity = UserEntity;

  public async markAsRead(userId, maxReadNotificationId) {
    return await this.put(`${this.endpoint}/${userId}/mark-as-read`, { maxReadNotificationId });
  }

  public async updateDisplayLanguage(userId, language) {
    return await this.put(`${this.endpoint}/${userId}/language`, { language: language });
  }
}

<template functional>
  <li class="c-filterable-select__dropdown__item"
      :class="[
        {
          'hover': props.parent.isHover(props.item),
          'heading': props.item.isHeading,
          'disabled': props.item._option_disabled,
        },
        data.class, data.staticClass
      ]"
      @mouseenter="props.parent.hoverItem(props.item)"
      @click.stop.prevent="props.parent.itemSelected(props.item)">
    <slot/>
  </li>
</template>
<script>
  export default {
    props: {
      parent: {type: Object, required: true},
      item: {type: [Object, String, Number], required: true},
    }
  }
</script>

<template>
  <div class="el-input" :class="{'el-input--small': size === 'small'}">
    <input v-model="c_value" v-on="myListeners" v-bind="$attrs"
           @focus="handleFocus"
           @blur="handleBlur"
           @change="handleChange"
           type="text" autocomplete="off" class="el-input__inner" />

    <div class="el-input__suffix" v-if="$slots.suffix">
      <div class="el-input__suffix-inner">
        <slot name="suffix"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {InputMixin} from "@/components/Form/mixins/input-mixin";
  // el-inputを使うと、勝手にupdateRenderしてパフォーマンスが落ちるので、おなじようなものを作成
  export default {
    mixins: [InputMixin],

    props: {
      value: {required: true },
      size: { type: String, required: false},
    },
    computed: {
      myListeners() {
        const { input, ...listeners } = this.$listeners;
        return listeners;
      },
      c_value: {
        get() {
          return this.value;
        },
        set(val){
          this.$emit('input', val);
        }
      }
    },
  }
</script>

























import { Component, Prop, Vue } from 'vue-property-decorator';
import {judgeLang, LangDict, setLang} from "@/bootstraps/locale";
import UserRepository from "@/repositories/user-repository";
import LoadingHandler from "@/utils/loading-handler";

@Component
export default class extends Vue {
  private visible = true;
  private lang = judgeLang();

  private readonly LangDict = LangDict;

  private submit() {
    setLang(this.lang);

    LoadingHandler(() => {
      return (new UserRepository()).updateDisplayLanguage(this.$auth.user.id, this.lang);
    }).then(() => {
      this.$message({
        message: this.$t('言語を変更しました。画面を再読み込みしてください'),
        type: 'success'
      });
      this.$emit('close');
    });
  }

}

import Vue from 'vue';
import Router, {Location, NavigationGuardNext, Route} from 'vue-router';
import Auth from '@/libs/auth';
import {alert} from '@/utils/notification';
import auth from "@/libs/auth";
import {AppTypes} from "@/app-types";
import {routesForLabel, routesForSpec} from "@/routes.js";
import {RawLocation} from "vue-router/types/router";
import {putRedirectTo} from "@/views/common/auth/handle-redirect";

Vue.use(Router);

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash};
    } else if (savedPosition) {
      return savedPosition;
    } else {
      // ページ遷移時にScrollTopする
      return { x: 0, y: 0 }
    }
  }
});

export function getHrefByRoute(appType:AppTypes, location: Location) {
  const rt = new Router({mode: 'history', base: process.env.BASE_URL});
  const routes = appType === AppTypes.Label ? routesForLabel : routesForSpec;
  rt.addRoutes(routes);
  location.params = { ...location.params, ...router.currentRoute.params };
  return rt.resolve(location);
}


function getAppType(route: Route): AppTypes[keyof AppTypes]  {
  const m = route.matched[0];
  if (!m || !m.meta) return 'label';
  return route.matched[0].meta.type || 'label';
}

// 認証・認可処理
router.beforeEach(async (to:Route, from, next: NavigationGuardNext<Vue>) => {
  // set title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const type = getAppType(to);
  const titleSuffix = type === 'spec' ?
    ' | スマート食品規格書' :
    type === 'agency' ? ' | スマート食品表示代行' : ' | スマート食品表示';
  const title = nearestWithTitle ? nearestWithTitle.meta.title : '';
  document.title = title + titleSuffix;

  try {
    // 認証が必要なページへの処理
    const redirect = (message, name) => {
      history.pushState(null, null as any, location.href); // ブラウザバックできるように
      putRedirectTo(to.fullPath);
      next({ name: name } );
      alert(message, {type: 'warning' });
    };

    const requiresAuth = to.matched.some(record => record.meta.guard === 'auth');
    if (requiresAuth) {
      if (!Auth.isAuthenticated) {
        return redirect('ログインが必要です', 'auth.login');
      }

      // サブスク登録無し(会員登録途中)
      if (!Auth.user.hasAnySubscription) {
        return redirect('クレジット情報が未入力です', 'auth.signup.register-card');
      }

      // 該当サービス契約無し
      if (
        (type === 'label' && !Auth.user.hasLabelSubscription) ||
        (type === 'spec' && !Auth.user.hasSpecSubscription)
      ) {
        putRedirectTo(to.fullPath);
        return next({ name: 'add-subscription' });
      }

      // 会社IDが必要なページへの処理
      if(to.params.companyId === 'me') {
        return next({ name: to.name as string, params: { ...to.params, companyId: auth.user.company.id.toString() } });
      }
    }

    // 企業管理者専用ページ
    const onlyCompanyAdmin = to.matched.some(record => record.meta.guard === 'company-admin');
    if (onlyCompanyAdmin) {
      if (!Auth.isAuthenticated || !Auth.user.isCompanyAdmin) {
        return redirect('この画面は管理者専用です', '/mypage');
      }
    }

    // ログイン済みの場合の処理
    const onlyGuest = to.matched.some(record => record.meta.guard === 'guest');
    if (onlyGuest && Auth.isAuthenticated) {
      return next({name: 'logged-in', params: {companyId: Auth.user.company.id as any}});
    }

    return next();
  } catch (ex) {
    alert(ex.message);
    throw ex;
  }
});

export default router;

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { mixin as removeWhitespaceMixin } from 'vue-remove-whitespace';
  export default {
    mixins: [ removeWhitespaceMixin],
  }
</script>

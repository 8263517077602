export enum AppTypes {
  Label = 'label',
  Spec = 'spec',
  Agency = 'agency'
}

const appType:AppTypes = location.pathname.startsWith('/spec') ? AppTypes.Spec :
  location.pathname.startsWith('/agency') ? AppTypes.Agency : AppTypes.Label;

export default appType;

// // configure language
import VueI18n from "vue-i18n";
import ElementLocale from 'element-ui/lib/locale';

export function setElementUILocale(lang) {
  const locale = lang === 'cn' ? 'zh-CN' : lang;
  const messages = {
    [locale]: require(`element-ui/lib/locale/lang/${locale}`).default,
  };
  const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: messages, // set locale messages
  })
  ElementLocale.i18n((key, value) => i18n.t(key, value))
}
setElementUILocale('ja'); // dummy

import {
  Loading,
  MessageBox,
  Message,
  Notification,
  Dialog,
  Input,
  InputNumber,
  Autocomplete,
  Form,
  FormItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Radio,
  RadioButton,
  RadioGroup,
  Tooltip,
  Popover,
  Popconfirm,
  Carousel,
  CarouselItem,
  Pagination,
  Drawer,
  Switch
} from "element-ui";
// import {
// } from 'element-ui/lib';

import {
  Loading as LoadingLib,
  MessageBox as MessageBoxLib,
  Message as MessageLib,
} from 'element-ui/lib';

// import 'element-ui/lib/__styleLibraryName__/__component-name__.css';

import {VueConstructor} from 'vue';
import {ElMessageBoxOptions, MessageBoxData} from "element-ui/types/message-box";

export function registerElementUIComponents(Vue: VueConstructor) {
  Vue.component('ElTable', () => import(/* webpackChunkName: "el-table" */ 'element-ui/lib/table'));
  Vue.component('ElTableColumn', () => import(/* webpackChunkName: "el-table" */ 'element-ui/lib/table-column'));
  Vue.component('ElSelect', () => import(/* webpackChunkName: "el-form" */ 'element-ui/lib/select'));
  Vue.component('ElOption', () => import(/* webpackChunkName: "el-form" */ 'element-ui/lib/option'));
  Vue.component('ElOptionGroup', () => import(/* webpackChunkName: "el-form" */ 'element-ui/lib/option-group'));
  Vue.component('ElTabs', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/tabs'));
  Vue.component('ElTabPane', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/tab-pane'));
  Vue.component('ElDropdown', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/dropdown'));
  Vue.component('ElDropdownMenu', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/dropdown-menu'));
  Vue.component('ElDropdownItem', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/dropdown-item'));
  Vue.component('ElDatePicker', () => import(/* webpackChunkName: "el-component" */ 'element-ui/lib/date-picker'));

  Vue.component("ElInput", Input);
  Vue.component("ElInputNumber", InputNumber);
  Vue.component("ElAutocomplete", Autocomplete);
  Vue.component("ElDialog", Dialog);
  Vue.component("ElForm", Form);
  Vue.component("ElFormItem", FormItem);
  Vue.component("ElCheckbox", Checkbox);
  Vue.component("ElCheckboxButton", CheckboxButton);
  Vue.component("ElCheckboxGroup", CheckboxGroup);
  Vue.component("ElRadio", Radio);
  Vue.component("ElRadioButton", RadioButton);
  Vue.component("ElRadioGroup", RadioGroup);
  Vue.component("ElTooltip", Tooltip);
  Vue.component("ElPopover", Popover);
  Vue.component("ElPopconfirm", Popconfirm);
  Vue.component("ElCarousel", Carousel);
  Vue.component("ElCarouselItem", CarouselItem);
  Vue.component("ElDrawer", Drawer);
  Vue.component('ElPagination', Pagination);
  Vue.component('ElSwitch', Switch);

  Vue.use(Loading.directive);
  if (process.env.NODE_ENV === 'testing') {
    // jestでなぜかうまくいかないので一旦これで回避
    // スタイルの当たり方が微妙に異なる
    Vue.prototype.$loading = LoadingLib.service;
    Vue.prototype.$msgbox = MessageBoxLib;
    Vue.prototype.$alert = MessageBoxLib.alert;
    Vue.prototype.$prompt = MessageBoxLib.prompt;
  } else {
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$prompt = MessageBox.prompt;
  }
  Vue.prototype.$confirm = async (message:string, title:string, opt?:ElMessageBoxOptions) => {
    const option = Object.assign({
      confirmButtonClass: 'c-button primary-inverse no-focus',
      cancelButtonClass: 'c-button primary no-focus'
    }, opt);
    return MessageBox.confirm(message, title, option);
  };
  Vue.prototype.$confirm2 = async (message:string, title:string, opt?:ElMessageBoxOptions) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$confirm(message, title, opt).then((res: MessageBoxData) => {
        resolve(true);
      }).catch((err: MessageBoxData) => {
        if(err === 'cancel' || err === 'close') {
          resolve(false);
          return;
        }
        throw err;
      });
    });
  };
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = (opt) => {
    // opt.message = i18n.t(opt.message);
    const instance = Message(Object.assign({showClose: true, duration: 3000, appendTo: '#alert-position'}, opt));

    const appendTo = opt.appendTo;
    if (appendTo && document.querySelector(appendTo)) {
      const $appendTo = document.querySelector(appendTo)!;
      $appendTo.appendChild(instance.$el);
      ($appendTo as any).style.position = 'relative';
      (instance.$el as any).style.position = 'static';
    }

    return instance;
  };
}


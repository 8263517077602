import ObjectUtils from "@/utils/object-utils";
import CompanyDepartmentEntity from "@/entities/company-department-entity";

export interface IApprovalStep {
  groups: IApprovalStepGroup[],
  completionConditionType: ApprovalFlowSettingStepCompletionType
}
export interface IApprovalStepGroup {
  isAllRequired: boolean;
  requiredApprovalNumber: number | null;
  users: IApprovalStepGroupUser[];
}
export interface IApprovalStepGroupUser {
  userName: string;
}

// api/App/Models/ApprovalRequestStepに同じロジックの実装あり
export function makeStepDescription(step: IApprovalStep ): string {
  // 「佐藤 エリカ」の承認
  // 「佐藤 エリカ、吉田 まなぶ、のいずれか1名」の承認
  // 「佐藤 エリカ、吉田 まなぶ、のいずれか1名」もしくは「斎藤 たけし、吉田 俊介、山本 敬太郎、の全員」の承認
  // 「佐藤 エリカ、吉田 まなぶ、のいずれか1名」と「斎藤 たけし、吉田 俊介、の全員」の承認
  const groups = step.groups.map((g) => {
    const userNames = g.users.map((u) => u.userName);
    if (userNames.length === 1) {
      return `「${userNames[0]}」`;
    }
    const requiredNumLabel = g.isAllRequired ? 'の全員' : `のいずれか${g.requiredApprovalNumber}名`;
    return `「${userNames.join('、')}、${requiredNumLabel}」`
  });
  const glue = step.completionConditionType === ApprovalFlowSettingStepCompletionType.Or ? 'もしくは' : 'と';
  return groups.join(glue) + 'の承認';
}

export class ApprovalFlowSettingEntity {
  public id!:number;
  public forAcceptance!: boolean;
  public name!: string;

  public departments: CompanyDepartmentEntity[] = [];
  public steps: ApprovalFlowSettingStepEntity[] = [];

  constructor(init:Partial<ApprovalFlowSettingEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.departments = init.departments || [];

    if(init.steps) {
      this.steps = init.steps.map(step => new ApprovalFlowSettingStepEntity(step));
    }
  }
}

export enum ApprovalFlowSettingStepCompletionType {
  Or = 1,
  And = 2,
}
export const ApprovalFlowSettingStepCompletionTypeDict = {
  [ApprovalFlowSettingStepCompletionType.Or]: 'いずれか一つの承認グループ',
  [ApprovalFlowSettingStepCompletionType.And]: 'すべての承認グループ',
}

export class ApprovalFlowSettingStepEntity implements IApprovalStep {
  public id!:number;
  public name!: string;
  public completionConditionType!: ApprovalFlowSettingStepCompletionType;

  public groups: ApprovalFlowSettingStepGroupEntity[] = [];

  constructor(init:Partial<ApprovalFlowSettingStepEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.groups) {
      this.groups = init.groups.map(group => new ApprovalFlowSettingStepGroupEntity(group));
    }
  }
  public get completionConditionTypeLabel(): string {
    return ApprovalFlowSettingStepCompletionTypeDict[this.completionConditionType];
  }
}

export class ApprovalFlowSettingStepGroupEntity implements IApprovalStepGroup {
  public id!:number;
  public requiredApprovalNumber!: number | null;

  public $isAllRequired!: boolean;

  public users: { userId:number, userName: string }[] = [];

  constructor(init:Partial<ApprovalFlowSettingStepGroupEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.$isAllRequired = init.requiredApprovalNumber === null;
    this.users = (init.users || []).map((user: any) => ({ userId: user.id, userName: user.name }));
  }

  public get isAllRequired(): boolean {
    return this.requiredApprovalNumber === null;
  }
}

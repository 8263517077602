import '@babel/polyfill';
import '@/utils/polyfills.js';

import '@/bootstraps/injection-dependency';

import Vue from 'vue';
import {registerErrorHandling, setSentryUser} from "@/bootstraps/error-handler";
import {i18n, judgeBrowserLang, LangType, setLang} from "@/bootstraps/locale";
import '@/bootstraps/register-components';
import { registerElementUIComponents }  from "@/bootstraps/register-element-ui";
registerElementUIComponents(Vue);

import '@/bootstraps/register-element-ui';
import '@/sass/global.scss';
import {routesForAgent, routesForLabel, routesForSpec} from './routes';
import router from './router';

import appType, {AppTypes} from "@/app-types";
import ApplicationUpdateWatcher from '@/utils/application-update-watcher';
import AuthRepository from "@/repositories/auth-repository";
import Auth, {AuthClass} from "@/libs/auth";
import App from '@/App.vue';
import HeaderComponentLabel from "@/components/Layout/Header/HeaderComponentLabel.vue";
import HeaderComponentSpec from "@/components/Layout/Header/HeaderComponentSpec.vue";
import ResponsiveHeaderComponentLabel from "@/components/Layout/Header/ResponsiveHeaderComponentLabel.vue";
import ResponsiveHeaderComponentSpec from "@/components/Layout/Header/ResponsiveHeaderComponentSpec.vue";
import {initClarity, initSmartlook} from "@/bootstraps/register-session-recording";
import {setElementUILocale} from "@/bootstraps/register-element-ui";

function setCssLocale() {
  document.documentElement.style.setProperty("--required-content", `'${i18n.t('common.required')}'`);
  document.documentElement.style.setProperty("--optional-content", `'${i18n.t('common.optional')}'`);
}

Vue.config.productionTip = process.env.NODE_ENV !== 'production';

registerErrorHandling();

document.documentElement.style.setProperty("--color-primary--label", '#E78D59');
document.documentElement.style.setProperty("--color-primary--spec", "#3FB0D1");
document.documentElement.style.setProperty("--color-primary--agency", "#EF5A5A");
if (appType === AppTypes.Label) {
  document.documentElement.style.setProperty("--color-primary", 'var(--color-primary--label)');
  document.documentElement.style.setProperty("--color-primary-rgb", '231,141,89');
  router.addRoutes(routesForLabel);
  Vue.component('HeaderComponent', HeaderComponentLabel);
  Vue.component('ResponsiveHeaderComponent', ResponsiveHeaderComponentLabel);
} else if(appType === AppTypes.Agency) {
  document.documentElement.style.setProperty("--color-primary", 'var(--color-primary--agency)');
  document.documentElement.style.setProperty("--color-primary-rgb", '239,90,90');
  router.addRoutes(routesForAgent);
} else {
  document.documentElement.style.setProperty("--color-primary", 'var(--color-primary--spec)');
  document.documentElement.style.setProperty("--color-primary-rgb", '63,176,209');
  router.addRoutes(routesForSpec);
  Vue.component('HeaderComponent', HeaderComponentSpec);
  Vue.component('ResponsiveHeaderComponent', ResponsiveHeaderComponentSpec);
}
Vue.prototype.$appType = appType;
Vue.prototype.$auth = Auth;

import { Path, Values, Locale, } from 'vue-i18n/types';

declare module 'vue/types/vue' {
  interface Vue {
    $auth: AuthClass,
    $appType: string,
    /** @ts-ignore */
    $t: (key: Path, values?: Values) => string;
    $confirm2: (message: string, title?: string, options?: any) => Promise<boolean>;
  }
}

(new AuthRepository).retrieve().then(user => {
  if (user) {
    Auth.login(user);
    setSentryUser(user);
    // TODO: Refactor
    const lang = appType === AppTypes.Spec ? user.displayLanguage : 'ja';
    setLang(lang);
    setElementUILocale(lang); // setLangにおきたいが、バグるので一旦ここ
  } else {
    const lang = appType === AppTypes.Spec ? judgeBrowserLang() : 'ja';
    setLang(lang)
    setElementUILocale(lang);
  }
  setCssLocale();

  new Vue({
    router,
    i18n,
    render: (h) => h(App),
    created() {
      const watcher = new ApplicationUpdateWatcher();
      watcher.startWatch(`/${appType}`);
    },
    mounted() {
      if(user) {
        initSmartlook(user);
        initClarity(user);
      }
    }
  }).$mount('#app')
});

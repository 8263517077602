import pickBy from 'lodash/pickBy';

class ObjectUtils {

  public assignLiteralFields(target, source:any) {
    Object.assign(target, this.getOnlyLiteral(source));
  }

  public getOnlyLiteral(source:any = this) {
    const isObject = o => o === Object(o) && !Array.isArray(o);
    return pickBy(source, ((value, key) => !isObject(value) && !Array.isArray(value)));
  }

  public excludeObject(source:any, keepProps:string[] = []) {
    const isObject = o => o === Object(o) && !Array.isArray(o);
    const isObjectArray = arr => Array.isArray(arr) && arr.some(i => isObject(i));
    return pickBy(source, ((value:any, key:string) => keepProps.includes(key) || (!isObject(value) && !isObjectArray(value))));
  }
}
export default new ObjectUtils();

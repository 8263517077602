import RepositoryBase from "@/repositories/repository-base";
import NotificationEntity from "@/entities/notification-entity";
import appType, {AppTypes} from "@/app-types";

export class NotificationRepository extends RepositoryBase<NotificationEntity> {
  protected ctor:new(data) => NotificationEntity = NotificationEntity;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/notifications`
  }

  public async findNotificationsForCurrentApp(): Promise<NotificationEntity[]> {
    const res = await this.get(this.endpoint, {appType: appType});
    return res.data.map(d => new NotificationEntity(d));
  }
}

import ObjectUtils from "@/utils/object-utils";
import {CompanyRole} from "@/entities/user-entity";
import {CompanyBasicEntity} from "@/entities/company-entity";
import parseISO from "date-fns/parseISO";
import CompanyDepartmentEntity, {getDepartmentNames} from "@/entities/company-department-entity";
import {i18n} from "@/bootstraps/locale";

export default class InvitingMemberEntity {
  public id!:number;
  public companyId!:number;
  public email!:string;
  public companyRole!:CompanyRole;
  public createdAt!:Date;

  public canAccessAllDeptResources!: boolean;
  public departments:CompanyDepartmentEntity[] = [];

  public company!:CompanyBasicEntity;

  public get roleName(): string {
    const role = this.companyRole;
    return role === CompanyRole.Admin ? i18n.t('管理者') :
      (role === CompanyRole.Operator ? i18n.t('編集メンバー') : i18n.t('閲覧メンバー') ) ;
  }

  public get companyName() {
    if (!this.company) return '';
    return this.company.name;
  }

  constructor(init:Partial<InvitingMemberEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }

    if (init.company) {
      this.company = new CompanyBasicEntity(init.company);
    }
  }

  public getDepartmentNames(): string[] {
    return getDepartmentNames(this.canAccessAllDeptResources, this.departments);
  }
}
























































































































import Notification from './Notification.vue';
import GetRouteMixin from "./GetRouteMixin.js";
import appType, {AppTypes} from "@/app-types";
import {Component, Vue} from "vue-property-decorator";
import {PaymentChannel} from "@/repositories/company/payment-repository";
import {judgeLang} from "@/bootstraps/locale";
import LangSwitcher from "@/components/Layout/Header/LangSwitcher.vue";
import UserApprovalRequestRepository from "@/repositories/user-approval-request-repository";
import {subscribeApprovalRequestTodoCountChanged} from "@/events/header-events";

@Component({
  mixins: [GetRouteMixin],
  components: {
    LangSwitcher,
    Notification,
  }
})
export default class extends Vue {
  private readonly appType = appType;
  private currentLang = judgeLang();
  private langSwitcherVisible = false;
  private approvalRequestTodoCounts = {requested: 0, requesting: 0};

  private readonly AppTypes = AppTypes;

  private get langCountry() {
    return this.currentLang === 'en' ? 'us' : this.currentLang;
  }

  private created() {
    this.watchApprovalRequestTodoCounts();
  }
  // Event駆動にする
  private watchApprovalRequestTodoCounts() {
    const fiveMin = 1000 * 60 * 5;
    this.fetchApprovalRequestCount();
    setInterval(this.fetchApprovalRequestCount, fiveMin);

    subscribeApprovalRequestTodoCountChanged(this.fetchApprovalRequestCount);
  }
  private async fetchApprovalRequestCount() {
    if (!this.$auth.isAuthenticated) return;
    if (!this.$auth.user.companySetting.isApprovalFlowEnabled) return;

    this.approvalRequestTodoCounts = await (new UserApprovalRequestRepository(this.$auth.user.id)).getTodoCounts();
  }

  public get showInvoices(): boolean {
    if (!this.$auth.user.isCompanyAdmin) return false;

    const s = this.$auth.user.getSubscription(appType);
    if (!s) return false;
    return s.paymentChannel !== PaymentChannel.Invoice;
  }

  private get showLang() {
    return appType === AppTypes.Spec;
  }
  private get showApproval() {
    return appType === AppTypes.Spec && this.$auth.user.companySetting.isApprovalFlowEnabled;
  }

  private openLangSwitcher() {
    if (!this.showLang) return;
    this.langSwitcherVisible = true;
  }
}
